<template>
  <v-sheet class="ma-2">
    <v-banner v-if="movingMode" single-line>
      <v-icon slot="icon" color="warning" size="36">
        mdi-arrow-left-top-bold
      </v-icon>
      移動モード：移動先を確認して、日表示にしてからクリックしてください

      <template v-slot:actions>
        <v-btn color="primary" text @click="cancelMove"> キャンセル </v-btn>
      </template>
    </v-banner>

    <div v-if="mode == 'day'" class="d-flex justify-end">
      <v-checkbox v-for="p in places" :key="p.id" v-model="p.show" @input="console.log($event)" :label="p.text" class="mr-2"></v-checkbox>
    </div>
    <v-calendar
      v-if="mode != 'list'"
      :type="type"
      :value="value"
      :events="events"
      first-time="8:00"
      interval-count="12"
      :weekdays="weekdays"
      :event-more="false"
      :event-category="(s) => s.category.text"
      event-more-text="{0} more..."
      :categories="places.filter(s => s.show)"
      :category-text="(s) => s.text"
      :category-show-all="true"
      @click:date="dateClick"
      @click:event="eventClick"
      @mousedown:event="startDrag"
      @mousedown:time-category="startTime"
      @mousemove:time-category="mouseMove"
      @mouseup:time-category="endDrag"
      style="background-color:lightgrey"
    >
      <template v-slot:event="{ event }">
        <visit-name :value="event" :mode="mode"></visit-name>
      </template>

      <template v-slot:day-label-header="{ date }">
        <day-label-header :value="new Date(date)"></day-label-header>
      </template>

      <template v-slot:day-label="{ date }">
        <day-label :value="new Date(date)" @buttonClick="dateClick"></day-label>
      </template>

    </v-calendar>

    <visitation-list v-else @rowClick="listEventClick"></visitation-list>

    <visit-edit-dialog
      v-if="eventDialog"
      v-model="eventDialog"
      :event="selectedEvent"
      @ok="update"
      @cancel="onEventDialogCancel"
    ></visit-edit-dialog>
  </v-sheet>
</template>

<style scope>
.v-calendar-weekly .v-calendar-weekly__day {
  min-height: 6.0rem;
}
@media print {
  .v-calendar {
    background-color:white !important;
  }
  .v-calendar .v-event-timed {
    background-color:white !important;
    color: black !important;
  }
  .v-event-timed.yellow {
    display:none;
  }
}
</style>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";
import VisitEditDialog from "../components/dialog/VisitEditDialog.vue";
import VisitName from "../components/VisitName.vue";
import qreki from "../plugins/qreki";
import DayLabel from "../components/calendar/DayLabel.vue";
import DayLabelHeader from "../components/calendar/DayLabelHeader.vue";
import VisitationList from "../components/calendar/VisitationList.vue";

export default {
  components: {
    VisitEditDialog,
    VisitName,
    DayLabel,
    DayLabelHeader,
    VisitationList
  },
  data: () => ({
    keyword: "",
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    selectedEvent: null,
    extendOriginal: null,
    eventDialog: false,
    koyomi: qreki,
    weekdays: [1, 2, 3, 4, 5, 6, 0],
  }),
  mounted: function () {
    this.setPlace(parseInt(this.$route.params.place));
    this.selectAsync({ type: "month", value: this.value });
  },
  watch: {
    $route() {
      this.setPlace(this.$route.params.place);
      this.selectAsync({ type: "month", value: this.value });
    },
  },
  methods: {
    ...mapMutations("visits", ["change", "setPlace"]),
    ...mapActions(["cancelMove", "finishMove"]),
    ...mapActions("visits", ["selectAsync", "updateAsync"]),
    search() {
      this.selectAsync({ type: "list", keyword: this.keyword });
    },
    dateClick(day) {
      this.selectAsync({ type: "day", value: day });
    },
    eventClick(event) {
      this.selectedEvent = event.event;
      this.eventDialog = true;
    },
    listEventClick(item) {
      this.selectedEvent = item;
      this.eventDialog = true;
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.movingMode) {
        // 移動モード
        const ev = this.movingEvent.event;
        const mst = moment(ev.start);
        const med = moment(ev.end);
        const duration = med.diff(mst, "seconds");
        ev.start = this.roundTime(mouse);
        ev.end = moment(ev.start).add(duration, "seconds").unix() * 1000;
        ev.place = tms.category.id,
        ev.category = tms.category,
        //this.finishMove();
        this.selectedEvent = ev;
        this.eventDialog = true;
        return;
      }

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        const mtime = moment(this.createStart);
        //console.log(tms);
        const over2023 = (2023 <= mtime.year());
        const isSaturdayOrSunday = (mtime.day() == 0 || mtime.day() == 6);
        const isHoliday = (this.koyomi[mtime.year()][mtime.month() + 1][mtime.date()].holiday != '');
        const koma = 60;
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: "#666666",
          start: this.createStart,
          end: this.createStart + koma * 60 * 1000,
          receipt: moment(new Date()).unix() * 1000,
          timed: true,
          customer: {},
          category: tms.category,
          place: tms.category.id,
          celemony: {
            onTheDay: 0,
            todoke: 2,
            familyPhoto: 0,
          },
        };
        console.log(this.createEvent.category);
        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const koma = 60;
        let max = Math.max(mouseRounded, this.createStart);
        if (min == max) {
          max = min + 60 * koma * 1000;
        }

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      if (this.createEvent) {
        this.selectedEvent = this.createEvent;
        this.eventDialog = true;
        const i = this.events.indexOf(this.selectedEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            //this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    eventFromChip(time, category) {
      time.category = category;
      this.startTime(time);
      this.endDrag();
    },
    roundTime(time) {
      const mtime = moment(time);
      const stime = moment(mtime).startOf("day").add(10, "hours");
      const over2023 = (2023 <= mtime.year());
      const isSaturdayOrSunday = (mtime.day() == 0 || mtime.day() == 6);
      const isHoliday = (this.koyomi[mtime.year()][mtime.month() + 1][mtime.date()].holiday != '');
      const koma = 60;
      const roundTimes = [];
      for (var i = 0; i < 16; i++) {
        const ktime = moment(stime).add(koma * i, "minutes");
        roundTimes.push(ktime);
      }
      const availableTimes = roundTimes.filter((s) => s.isSameOrBefore(mtime));
      if (0 < availableTimes.length) {
        const rtime = roundTimes.filter((s) => s.isSameOrBefore(mtime)).pop();
        return rtime.unix() * 1000;
      } else {
        return moment(mtime.format("YYYY/MM/DD HH:mm")).unix() * 1000;
      }
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    async update(event) {
      //console.log('update')
      this.finishMove();
      await this.updateAsync(event);
    },
    async onEventDialogCancel() {
      this.finishMove();
      if(this.mode != 'list' && this.mode != 'month') {
        await this.selectAsync({});
      }
    },
  },
  computed: {
    ...mapState(["movingMode", "movingEvent"]),
    ...mapState("visits", ["events", "value", "type"]),
    ...mapState("place", { places: "data" }),
    type: {
      get() {
        if (this.$store.state.visits.type != "list") {
          if (this.$store.state.visits.type == "day") {
            return "category";
          } else {
            return this.$store.state.visits.type;
          }
        } else {
          return "month";
        }
      },
    },
    mode: {
      get() {
        return this.$store.state.visits.type;
      },
    },
    vacancies: {
      get() {
        const mtime = moment(this.value);
        const stime = moment(mtime).startOf("day").add(10, "hours");
        var vacancies = {};
        for(var j in this.places) {
          var std = this.places[j];
          var koma = 60;
          var vcount = 8;
          const vacancy = [];
          for (var i = 0; i < vcount; i++) {
            const ktime = moment(stime).add(koma * i, "minutes");
            vacancy.push({
                key: ktime.unix(),
                time: {
                  year: ktime.year(),
                  month: ktime.month() + 1,
                  day: ktime.date(),
                  hour: ktime.hour(),
                  minute: ktime.minute(),
                },
                text: ktime.format("HH:mm"),
              });
          }
          vacancies[std.id] = vacancy;
        }
        
        return vacancies;
      },
    },
    vacancy: {
      get() {
        const mtime = moment(this.value);
        const stime = moment(mtime).startOf("day").add(10, "hours");
        const over2023 = (2023 <= mtime.year());
        const isSaturdayOrSunday = (mtime.day() == 0 || mtime.day() == 6);
        const isHoliday = (this.koyomi[mtime.year()][mtime.month() + 1][mtime.date()].holiday != '');
        //console.log(isHoliday);
        const koma = 60;
        const vcount = (koma == 50 ? 8: 9);
        const vacancy = [];
        for (var i = 0; i < vcount; i++) {
          const ktime = moment(stime).add(koma * i, "minutes");
          if (
            this.events.findIndex(
              (e) => e.start <= ktime.toDate().getTime() && ktime.toDate().getTime() < e.end
            ) < 0
          ) {
            vacancy.push({key:ktime.unix(), time:{
              year:ktime.year(), 
              month:ktime.month() + 1,
              day:ktime.date(),
              hour:ktime.hour(),
              minute:ktime.minute()
              }, text:ktime.format('HH:mm')});
          }
        }
        //console.log(vacancy);
        return vacancy;
      },
    },
  },
};
</script>
