import { addDoc, collection, deleteDoc, doc, onSnapshot, query, setDoc } from 'firebase/firestore';
import { db } from '../../plugins/firebase';
const collectionName = "eventTypes";
export default {
    namespaced: true,
    state: {
        data: []
    },
    actions: {
        async load(context, {refresh=true}) {
            if(context.state.data.length == 0 || refresh) {
                //const db = getDatabase();
                const q = query(collection(db, collectionName));
                onSnapshot(q, (snapshot) => {
                    const data = [];
                    snapshot.forEach((doc) => {
                        const docData = doc.data();
                        docData.__id = doc.id;
                        data.push(docData);
                    })
                    context.commit('setData', data);
                });
            }
        },
        initEventTypes: {
            root:true,
            handler: async (context) => {await context.dispatch('load', {refresh:false})},
        },
        async update(context, item) {
            //const db = getDatabase();
            const docData = Object.assign({}, item);
            delete docData.__id;

            // データの整形
            docData.value = parseInt(docData.value);

            if(!item.__id) {
                await addDoc(collection(db, collectionName), docData);
            }
            else {
                const ref = await doc(collection(db, collectionName), item.__id);
                await setDoc(ref, docData);
            }
        },
        async delete(context, item) {
            //const db = getDatabase();
            if(item.__id) {
                const ref = await doc(collection(db, collectionName), item.__id);
                await deleteDoc(ref);
            }
        }
    },
    mutations: {
        setData(state, data) {
            state.data = data;
        }
    }
}