<template>
  <v-sheet>
    <v-data-table
      :headers="headers"
      :items="data"
      dense
      sort-by="value"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar dense elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="(dialog=true)">
            <v-icon>mdi-plus</v-icon>
            追加
          </v-btn>
        </v-toolbar>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.value"
                      label="ID"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.text"
                      label="名称"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.abbr"
                      label="略称"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-color-picker
                      dot-size="25"
                      v-model="editedItem.color"
                      swatches-max-height="200"
                    ></v-color-picker>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                キャンセル
              </v-btn>
              <v-btn color="blue darken-1" text @click="save"> 保存 </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >本当にこの項目を削除しますか？</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >キャンセル</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >削除</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.color`]="{ item }">
        <v-chip label :color="item.color" text-color="grey lighten-2">{{
          item.color
        }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
      <template v-slot:bottom> </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "ID",
        align: "end",
        value: "value",
      },
      { text: "名称", value: "text" },
      { text: "略名", value: "abbr" },
      { text: "カラー", value: "color" },
      { text: "操作", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
    },
    defaultItem: {
      value:"",
      text:"",
      abbr:"",
      color:"#FFFFFF",
    },
  }),

  computed: {
    ...mapState("eventTypes", ["data"]),
    formTitle() {
      return this.editedIndex === -1 ? "新規登録" : "項目の編集";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("eventTypes", ["load", "update", "delete"]),

    initialize() {
      this.load(false);
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.delete(this.editedItem);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.update(this.editedItem);
      this.close();
    },
  },
};
</script>