<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateString"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dateValue" @input="menu = false"></v-date-picker>
  </v-menu>
</template>
<style scoped>
  .v-text-field >>> input {
    font-size:1.3em;
  }
</style>
<script>
import moment from 'moment';
export default {
  props: {
    value: Number,
    label: String
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    date: {
      get() {
        let d = new Date();
        d.setTime(this.value);
        return d;
      },
      set(val) {
          let i = val.getTime();
        this.$emit("input", i);
      },
    },
    dateValue: {
      get() {
        return moment(this.date).format('YYYY-MM-DD');
      },
      set(val) {
        this.$emit("input", moment(val).unix() * 1000);
      }
    },
    dateString: {
      get() {
        return moment(this.date).format('YYYY/M/D(ddd)');
      },
      set(val) {
        this.date = new Date(val);
      },
    },
  },
};
</script>