<template>
  <span style="display:inline-block;white-space:normal;line-height:1.0rem;">{{ name }}</span>
</template>
<style scoped>
</style>
<script>
import moment from "moment";

export default {
  props: {
    value: Object,
    mode: String,
  },
  computed: {
    name: {
      get() {
        var result = "";
        // 時刻
        result = result + moment(this.value.start).format("HH:mm");

        // 区分
        if (this.value.eventType && this.value.eventType.abbr) {
          result = result + " " + this.value.eventType.abbr;
        }

        // 顧客
        if (this.value.customer && this.value.customer.name) {
          result = result + " " + this.value.customer.name;
        }

        // セット完了
        if (this.value.customer && this.value.customer.isSet == "★") {
          result = result + " " + "★";
        }

        // 営業担当
        if (this.value.customer.album) {
          result = result + " (" + this.value.customer.album + ")";
        }

        // 伝票番号
        if(this.value.slipNumber) {
          result = result + " " + this.value.slipNumber;
        }

        // 営業担当
        if (this.value.salesStaff) {
          result = result + " (" + this.value.salesStaff.name + ")";
        }

        // 週表示
        if (this.value.remarks && (this.mode == "day" || this.mode == "week")) {
          result = result + ' | ' + this.value.remarks;
        }

        return result;
      },
    },
  },
};
</script>