import { collection, getDocs, orderBy, query } from "firebase/firestore";
import Vue from "vue";
import Vuex from "vuex";
import calendar from './modules/calendar'
import eventTypes from './modules/eventTypes'
import users from './modules/users'
import auth from './modules/auth'
import visits from './modules/visits'
import studio from './modules/studio'
import place from './modules/place'
import preparationPlace from './modules/preparationPlace'
import { db } from "../plugins/firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    _scheduleTypes: null,
    movingMode: false,
    movingEvent: null,
    displayCategories:[],
    preparationPlaces:[],
  },
  actions: {
    startMove(context, ev) {
      context.commit('setMove', {move:true, ev:ev});
    },
    finishMove(context) {
      context.commit('setMove', {move:false, ev:null});
    },
    cancelMove(context) {
      context.commit('setMove', {move:false, ev:null});
    }
  },
  mutations: {
    setMove(state, {move, ev}) {
      state.movingMode = move;
      state.movingEvent = ev;
    },
    setScheduleTypes(state, types) {
      state.scheduleTypes = types;
    },
    setCategory(state, {category, value}) {
      if(value) {
        if(!state.displayCategories.includes(category)) {
          state.displayCategories.push(category)
        }
      } else {
        const idx = state.displayCategories.findIndex(s => s === category)
        if(idx >= 0) {
          state.displayCategories.splice(idx, 1)
        }
      }
    },
    setPreparationPlaces(state, places) {
      state.preparationPlaces = places;
    }
  },
  getters: {
    scheduleTypes(state){
      if(state._scheduleTypes == null) {
        //const db = getDatabase();
        const statusTypesRef = collection(db, "scheduleTypes");
        const q = query(statusTypesRef, orderBy("value"));
        //console.log("firebase!");
        getDocs(q).then(querySnapshot => {
          const data = querySnapshot.docs.map(d => d.data());
          state._scheduleTypes = data;
        });
      }
      return state._scheduleTypes;
    },
  },
  modules: {
    calendar,
    eventTypes,
    users,
    auth,
    visits,
    studio,
    place,
    preparationPlace
  },
});
