<template>
  <v-card class="ma-4">
    <v-card-title>バックアップのダウンロード</v-card-title>
    <v-card-subtitle
      ><strong>現在以降</strong>のすべてのデータをExcelファイルとしてダウンロードします。</v-card-subtitle
    >
    <v-card-actions class="justify-end">
      <download-excel
        class="export-excel-wrapper"
        :fetch="backup"
        type="xls"
        worksheet="data"
        :name="worksheetName"
      >
        <v-btn color="primary" text>ダウンロード</v-btn>
      </download-excel>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from "moment";
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    
  }),
  methods: {
    ...mapGetters("calendar", ["backup"]),
  },
  computed: {
    worksheetName: {
      get() {
        return moment().format("YYYY-MM-DD") + ".xls";
      },
    },
  },
};
</script>
