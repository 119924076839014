// 認証状態管理ストア
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from "../../plugins/firebase";
export default {
    namespaced: true,
    state: {
        // 現在のユーザー。
        currentUser: {
            id: "",
            email: "",
            role: "unauthorized"
        },
        // 認証状態。
        authenticated: false,
    },
    actions: {
        // ログイン
        async loginAsync(context, { email, password }) {
            // Firebase Authを初期化
            //const auth = getAuth();

            // サインインの実行
            return await signInWithEmailAndPassword(auth, email, password)
                .then((result) => result)
                .catch(async () => {
                    // エラーの場合はサインアウトして状態をリセットする
                    await signOut(auth);
                    context.commit('setUser', { id: "", email: "", role: "unauthorized" });
                    context.commit('setAuthenticated', false);
                    return false;
                });
        },
        // ログアウト
        async logoutAsync(context) {
            // Firebase Authを初期化
            //const auth = getAuth();

            // サインアウト
            await signOut(auth);

            // ユーザーを初期化
            context.commit('setUser', { id: "", email: "", role: "unauthorized" });
            context.commit('setAuthenticated', false);
        }
    },
    mutations: {
        setUser(state, user) {
            state.currentUser = user;
        },
        setAuthenticated(state, authenticated) {
            state.authenticated = authenticated;
        }
    },
    getters: {
        getAuthenticated(state) {
            return state.authenticated;
        }
    }
}