<template>
<v-sheet>
  <v-data-table
    :headers="headers"
    :items="events"
    :items-per-page="20"
    @click:row="rowClick"
    class="elevation-1"
  >
    <template v-slot:item.startDate="{ item }">
      <span>{{ item.start | toShortDateString }}</span>
    </template>

    <template v-slot:item.startTime="{ item }">
      <span>{{ item.start | toShortTimeString }}</span>
    </template>

    <template v-slot:item.place="{ item }">
      <span>{{ getPlace(item.place) }}</span>
    </template>

    <template v-slot:item.nameAndKana="{ item }">
      <span
        ><ruby>
          <rb>{{item.customer.name}}</rb>
          <rp>（</rp>
          <rt>{{item.customer.furigana}}</rt>
          <rp>）</rp>
        </ruby></span
      >
    </template>

    <template v-slot:item.receiptDate="{ item }">
      <span>{{ item.receipt | toShortDateString }}</span>
    </template>

    
  </v-data-table>
</v-sheet>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    headers: [
      { text: "日付", value: "startDate" },
      { text: "時刻", value: "startTime" },
      { text: "来店場所", value: "place"},
      { text: "氏名", value: "nameAndKana" },
      { text: "受付日", value: "receiptDate"},
    ]
  }),
  computed: {
    ...mapState("visits", ["events"]),
    ...mapState("place", {places:"data"})
  },
  methods: {
    rowClick(item) {
      this.$emit('rowClick', item);
    },
    getPlace(place) {
      for(var p in this.places) {
        var pp = this.places[p];
        if(pp.id == place){
          return pp.text;
        }
      }
      return "";
    }
  }
};
</script>
