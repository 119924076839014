import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from 'moment';
import "./plugins/firebase";
import "./plugins/dateFormat";
import Faker from "vue-faker";
import JsonExcel from 'vue-json-excel';
moment.locale('ja');

Vue.config.productionTip = false;
Vue.use(Faker, {locale: 'ja'});
Vue.component('downloadExcel', JsonExcel)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")