<template>
  <v-sheet class="ma-2">
    <v-banner v-if="movingMode" single-line>
      <v-icon slot="icon" color="warning" size="36">
        mdi-arrow-left-top-bold
      </v-icon>
      移動モード：移動先を確認して、日表示にしてからクリックしてください

      <template v-slot:actions>
        <v-btn color="primary" text @click="cancelMove"> キャンセル </v-btn>
      </template>
    </v-banner>

    <v-calendar
      v-if="mode != 'list'"
      :type="type"
      :value="value"
      :events="events"
      first-time="8:00"
      interval-count="12"
      :weekdays="weekdays"
      :event-more="false"
      :event-category="(s) => s.category ? s.category.text : ''"
      event-more-text="{0} more..."
      :categories="studios"
      :category-text="(s) => s.text"
      :category-show-all="true"
      @click:date="dateClick"
      @click:event="eventClick"
      @mousedown:event="startDrag"
      @mousedown:time-category="startTime"
      @mousemove:time-category="mouseMove"
      @mouseup:time-category="endDrag"
    >
      <template v-slot:event="{ event }">
        <event-name :value="event" :mode="mode"></event-name>
      </template>

      <template v-slot:day-label-header="{ date }">
        <day-label-header :value="new Date(date)"></day-label-header>
      </template>

      <template v-slot:day-label="{ date }">
        <day-label :value="new Date(date)" @buttonClick="dateClick"></day-label>
      </template>

      <template v-slot:category="{ category }">
        <h3 class="text-body-1 text-center">{{ category.text }}</h3>
        <v-sheet v-if="mode == 'day'" style="min-height:3rem" class="mb-2 d-print-none overflow-auto">
          <v-chip>空き枠:{{ vacancies[category.id].length }}件</v-chip>
          <v-chip
            v-for="v in vacancies[category.id]"
            :key="v.key"
            class="ms-4"
            @click="eventFromChip(v.time, category)"
          >
            {{ v.text }}
          </v-chip>
        </v-sheet>
      </template>
    </v-calendar>

    <schedule-list v-else @rowClick="listEventClick"></schedule-list>

    <event-edit-dialog
      v-if="eventDialog"
      v-model="eventDialog"
      :event="selectedEvent"
      :preparationPlaces="preparationPlaces"
      @ok="update"
      @cancel="onEventDialogCancel"
    ></event-edit-dialog>
  </v-sheet>
</template>

<style scope>
.v-calendar-weekly .v-calendar-weekly__day {
  min-height: 6rem;
}
@media print {
  .v-calendar .v-event-timed {
    background-color: white !important;
    color: black !important;
  }
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";
import EventEditDialog from "../components/dialog/EventEditDialog.vue";
import EventName from "../components/EventName.vue";
import qreki from "../plugins/qreki";
import DayLabel from "../components/calendar/DayLabel.vue";
import DayLabelHeader from "../components/calendar/DayLabelHeader.vue";
import ScheduleList from "../components/calendar/ScheduleList.vue";
import { collection, getDocs } from "firebase/firestore"
import { db } from "../plugins/firebase"

export default {
  components: {
    EventEditDialog,
    EventName,
    DayLabel,
    DayLabelHeader,
    ScheduleList,
  },
  data: () => ({
    keyword: "",
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    selectedEvent: null,
    extendOriginal: null,
    eventDialog: false,
    koyomi: qreki,
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    preparationPlaces:[],
  }),
  mounted: async function () {
    this.setStudio(parseInt(this.$route.params.studio ?? 1));

    const pdate = moment(this.$route.params.pdate);
    if(pdate.isValid()) {
      this.selectAsync({ type: "day", value:pdate.toDate()});
    } else {
      this.selectAsync({ type: "month", value: this.value });
    }

    // 準備場所コレクションをIDをキーにした連想配列として取得する
    this.preparationPlaces = (await getDocs(collection(db, "preparationPlaces"))).docs.map((doc) => ({id:doc.id, ...doc.data()}))
  },
  watch: {
    $route() {
      this.setStudio(this.$route.params.studio ?? 1);
      this.selectAsync({ type: "month", value: this.value });
    },
  },
  methods: {
    ...mapMutations("calendar", ["change", "setStudio"]),
    ...mapActions(["cancelMove", "finishMove"]),
    ...mapActions("calendar", ["selectAsync", "updateAsync"]),
    search() {
      this.selectAsync({ type: "list", keyword: this.keyword });
    },
    dateClick(day) {
      this.selectAsync({ type: "day", value: day });
    },
    eventClick(event) {
      this.selectedEvent = event.event;
      this.eventDialog = true;
    },
    listEventClick(item) {
      this.selectedEvent = item;
      this.eventDialog = true;
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);
      console.log(tms);
      if (this.movingMode) {
        // 移動モード
        const ev = this.movingEvent.event;
        const mst = moment(ev.start);
        const med = moment(ev.end);
        const duration = med.diff(mst, "seconds");
        ev.start = this.roundTime(mouse, tms.category.id);
        ev.end = moment(ev.start).add(duration, "seconds").unix() * 1000;
        //this.finishMove();
        this.selectedEvent = ev;
        this.eventDialog = true;
        return;
      }

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse, tms.category.id);
        //const mtime = moment(this.createStart);
        //console.log(mtime);
        var koma = this.calcKoma(this.createStart, tms.category.id)
        /*const over2023 = 2023 <= mtime.year();
        //const isSaturdayOrSunday = mtime.day() == 0 || mtime.day() == 6;
        const isHoliday =
          this.koyomi[mtime.year()][mtime.month() + 1][mtime.date()].holiday !=
          "";
        var koma = over2023 && (isSaturdayOrSunday || isHoliday) ? 50 : 40;*/
        console.log(koma)
        if(tms.category.id == 2) {
          koma = 30;
        }
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: "#666666",
          start: this.createStart,
          end: this.createStart + koma * 60 * 1000,
          receipt: moment(new Date()).unix() * 1000,
          timed: true,
          customer: {},
          category: tms.category,
          studio: tms.category.id,
          celemony: {
            onTheDay: 0,
            todoke: 2,
            familyPhoto: 0,
          },
        };
        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, tms.category.id);
        const min = Math.min(mouseRounded, this.createStart);
        var koma = 2024 <= moment(this.createStart).year() ? 50 : 40;
        if(tms.category.id == 2) {
          koma = 30;
        }
        let max = Math.max(mouseRounded, this.createStart);
        if (min == max) {
          max = min + 60 * koma * 1000;
        }

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      if (this.createEvent) {
        this.selectedEvent = this.createEvent;
        this.eventDialog = true;
        const i = this.events.indexOf(this.selectedEvent);
        if (i !== -1) {
          this.events.splice(i, 1);
        }
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            //this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    eventFromChip(time, category) {
      time.category = category;
      this.startTime(time);
      this.endDrag();
    },
    roundTime(time, categoryId ) {
      const mtime = moment(time);
      const stime = moment(mtime).startOf("day").add(10, "hours");
      var koma = this.calcKoma(time, categoryId);
      const roundTimes = [];
      for (var i = 0; i < 16; i++) {
        const ktime = moment(stime).add(koma * i, "minutes");
        roundTimes.push(ktime);
      }
      const availableTimes = roundTimes.filter((s) => s.isSameOrBefore(mtime));
      if (0 < availableTimes.length) {
        const rtime = roundTimes.filter((s) => s.isSameOrBefore(mtime)).pop();
        return rtime.unix() * 1000;
      } else {
        return moment(mtime.format("YYYY/MM/DD HH:mm")).unix() * 1000;
      }
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    async update(event) {
      this.finishMove();
      await this.updateAsync(event);
    },
    async onEventDialogCancel() {
      this.finishMove();
      if (this.mode != "list" && this.mode != "month") {
        await this.selectAsync({});
      }
    },
    calcKoma(time, categoryId) {
      const mtime = moment(time);
      var koma = 50;
      if(mtime.year() < 2024) {
        const isSaturdayOrSunday = mtime.day() == 0 || mtime.day() == 6;
        const isHoliday =
          this.koyomi[mtime.year()][mtime.month() + 1][mtime.date()].holiday !=
          "";
        koma = (isSaturdayOrSunday || isHoliday) ? 50 : 40;
      }
      if(categoryId == 2) {
        koma = 30;
      }
      return koma;
    }
  },
  computed: {
    ...mapState(["movingMode", "movingEvent"]),
    ...mapState("calendar", ["events", "value", "type"]),
    ...mapState("studio", { studios: "data" }),
    type: {
      get() {
        if (this.$store.state.calendar.type != "list") {
          if (this.$store.state.calendar.type == "day") {
            return "category";
          } else {
            return this.$store.state.calendar.type;
          }
        } else {
          return "month";
        }
      },
    },
    mode: {
      get() {
        return this.$store.state.calendar.type;
      },
    },
    vacancies: {
      get() {
        const mtime = moment(this.value);
        const stime = moment(mtime).startOf("day").add(10, "hours");
        var vacancies = {};
        for(var j in this.studios) {
          var std = this.studios[j];
          var koma = this.calcKoma(this.value, std.id)
          var vcount = koma == 50 ? 8 : 9;
          if(std.id == 2) {
            koma = 30;
            vcount = 12;
          }
          const vacancy = [];
          for (var i = 0; i < vcount; i++) {
            const ktime = moment(stime).add(koma * i, "minutes");
            if (
              this.events.findIndex(
                (e) =>
                  e.start <= ktime.toDate().getTime() &&
                  e.studio == std.id &&
                  ktime.toDate().getTime() < e.end
              ) < 0
            ) {
              vacancy.push({
                key: ktime.unix(),
                time: {
                  year: ktime.year(),
                  month: ktime.month() + 1,
                  day: ktime.date(),
                  hour: ktime.hour(),
                  minute: ktime.minute(),
                },
                text: ktime.format("HH:mm"),
              });
            }
          }
          vacancies[std.id] = vacancy;
        }
        
        //console.log(vacancies);
        return vacancies;
      },
    },
    vacancy: { //obsolute
      get() {
        const mtime = moment(this.value);
        const stime = moment(mtime).startOf("day").add(10, "hours");
        const over2023 = 2023 <= mtime.year();
        const isSaturdayOrSunday = mtime.day() == 0 || mtime.day() == 6;
        const isHoliday =
          this.koyomi[mtime.year()][mtime.month() + 1][mtime.date()].holiday !=
          "";
        //console.log(isHoliday);
        const koma = over2023 && (isSaturdayOrSunday || isHoliday) ? 50 : 40;
        const vcount = koma == 50 ? 8 : 9;
        const vacancy = [];
        for (var i = 0; i < vcount; i++) {
          const ktime = moment(stime).add(koma * i, "minutes");
          if (
            this.events.findIndex(
              (e) =>
                e.start <= ktime.toDate().getTime() &&
                ktime.toDate().getTime() < e.end
            ) < 0
          ) {
            vacancy.push({
              key: ktime.unix(),
              time: {
                year: ktime.year(),
                month: ktime.month() + 1,
                day: ktime.date(),
                hour: ktime.hour(),
                minute: ktime.minute(),
              },
              text: ktime.format("HH:mm"),
            });
          }
        }
        //console.log(vacancy);
        return vacancy;
      },
    },
  },
};
</script>
