/* eslint-disable no-unused-vars */
import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, orderBy, query, setDoc, where } from 'firebase/firestore';
import moment from 'moment';
import Vue from 'vue';
import moji from 'moji';
import { db } from '../../plugins/firebase';

export default {
    namespaced: true,
    state: {
        type: 'month',
        value: new Date(),
        events: [],
        reservations:[],
        keyword: '',
        salesStaffId: '',
        place:1,
        unscribe: () => {},
    },
    actions: {
        async selectAsync(context, { type, value, keyword, salesStaffId, place }) {
            context.commit('select', { type, value, keyword, salesStaffId, place });
            type = context.state.type;
            value = context.state.value;
            keyword = context.state.keyword;
            salesStaffId = context.state.salesStaffId;
            place = context.state.place;
            await context.dispatch('initPlace', null, {root:true});
            //console.log({after:'after', type, value, keyword, salesStaffId});

            // データ取得範囲の算出
            let start = moment(value).startOf(type);
            let end = moment(value).endOf(type);

            if(type == 'week') {
                // 週表示の場合、1日プラスする
                start = start.add(1, 'd');
                end = end.add(1, 'd');
            }

            //console.log({start: start.format('YYYY-MM-DD HH:mm'), end : end.format('YYYY-MM-DD HH:mm')});

            // クエリの定義
            //const db = getDatabase();
            var q = query(collection(db, "visit"));
            if (type == 'list') {
                // リスト表示の場合は全期間で表示
                if(keyword) {
                    var convertedKeyword = moji(keyword).convert('HK', 'ZK').convert('ZE', 'HE').toString();
                    convertedKeyword = convertedKeyword.replaceAll("-", "").replaceAll('−', '').replaceAll('ｰ', '');
                    //console.log(convertedKeyword);
                    q = query(q,
                        where("keywords", "array-contains", convertedKeyword)
                    );
                }
                else {
                    // キーワード未指定の場合は開始時刻で並び替え
                    q = query(q, orderBy("start", "desc"));
                }
                
                if(salesStaffId) {
                    q = query(q,
                        where("salesStaff.__id", "==", salesStaffId)
                        );
                } 
            }
            else {
                q = query(q, where("start", ">=", moment(start).toDate().getTime()));
                q = query(q, where("start", "<=", moment(end).toDate().getTime()));
            }

            //console.log({type:type, start:moment(start).format('YYYY/MM/DD HH:mm'), end:moment(end).format('YYYY/MM/DD HH:mm')});

            // データをクリア
            context.commit('clearEvent');

            // 既存の購読を解除
            context.state.unscribe();
            
            // スナップショットの接続
            const unscribe = onSnapshot(q, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    //console.log(change.type);
                    if(change.type == 'added') {
                        const data = change.doc.data();
                        data.__id = change.doc.id;
                        if(!data.place && data.category == null) data.place = 1;
                        if(type == 'day' || data.place == context.state.place) {
                            const category = context.rootState.place.data.filter(s => s.id == data.place)[0];
                            data.category = category;

                            context.commit('addEvent', data);
                        }
                    }
                    if(change.type == "modified") {
                        const data = change.doc.data();
                        data.__id = change.doc.id;
                        context.commit('modifyEvent', data);
                        if(type != 'day') {
                            if(data.place == context.state.place) {
                                context.commit('modifyEvent', data);
                            }
                            else{
                                context.commit('removeEvent', data);
                            }
                        } else {
                            context.commit('modifyEvent', data);
                        }
                    }
                    if(change.type == "removed") {
                        const data = change.doc.data();
                        data.__id = change.doc.id;
                        context.commit('removeEvent', data);
                    }
                });
            });
            context.commit('setUnscribe', unscribe);
        },
        async selectReserveAsync(context) {
            const btime = moment('2099/01/01');

            // クエリの定義
            //const db = getDatabase();
            var q = query(collection(db, "visit"));
            q = query(q, where("start", "==", btime.toDate().getTime()));

            // データをクリア
            context.commit('clearReservation');
            
            // スナップショットの接続
            onSnapshot(q, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    //console.log(change.type);
                    if(change.type == 'added') {
                        const data = change.doc.data();
                        data.__id = change.doc.id;
                        if(!data.studio && data.category == null) data.studio = 1;
                        const category = context.rootState.studio.data.filter(s => s.id == data.studio)[0];
                        data.category = category;
                        context.commit('addEvent', data);
                        context.commit('addReservation', data);
                    }
                    if(change.type == "modified") {
                        const data = change.doc.data();
                        data.__id = change.doc.id;
                        context.commit('modifyReservation', data);
                    }
                    if(change.type == "removed") {
                        const data = change.doc.data();
                        data.__id = change.doc.id;
                        context.commit('removeReservation', data);
                    }
                });
            });
        },
        async reserveAsync(context, item) {
            const stime = moment(item.start);
            const etime = moment(item.end);
            const duration = etime.diff(stime, "minutes");
            const btime = moment('2099/01/01');

            item.start = btime.unix() * 1000;
            item.end = btime.add(duration, "minutes").unix() * 1000;
            await context.dispatch('updateAsync', item);
        },
        async updateAsync(context, item) {
            //const db = getDatabase();
            const docData = Object.assign({}, item);
            delete docData.__id;

            // 終了時間を正規化する
            const stime = moment(item.start);
            const etime = moment(item.end);
            const ntime = moment(stime.format('YYYYMMDD') + etime.format('HHmm'), 'YYYYMMDDHHmm');
            docData.end = ntime.toDate().getTime();

            // キーワードを初期化する
            var names = [];
            if(docData.customer.name)
                names = names.concat(docData.customer.name.split(/[\u{20}\u{3000}]/u));
            if(docData.customer.furigana){
                var kanas = docData.customer.furigana.split(/[\u{20}\u{3000}]/u);
                kanas = kanas.map(k => moji(k).convert('HK', 'ZK').convert('ZE', 'HE').toString());
                names = names.concat(kanas);
            }
            var tels = [];
            tels = [docData.tel];
            tels = tels.filter(x => x !=null && x != "");
            tels = tels.map(k => moji(k).convert('HK', 'ZK').convert('ZE', 'HE').toString());
            tels = tels.map(k => k.replaceAll('-', '').replaceAll('−', '').replaceAll('ｰ', ''));
            
            docData.keywords = [...names, ...tels].filter(x => x !=null && x != "");
            docData.keywords = Array.from(new Set(docData.keywords)); // 重複削除
            
            docData.updated = new Date();
            if(!item.__id) {
                await addDoc(collection(db, 'visit'), docData);
            }
            else {
                const ref = await doc(collection(db, 'visit'), item.__id);
                await setDoc(ref, docData);
            }
        },
        async removeAsync(context, item) {
            //const db = getDatabase();
            const ref = await doc(collection(db, 'visit'), item.__id);
            if(ref) {
                await deleteDoc(ref);
            }
            //context.commit('removeReservation', item);
        }
    },
    getters: {
        async backup() {
            // クエリの定義
            const stime = moment();
            //const db = getDatabase();
            var q = query(collection(db, "visit"));
            q = query(q, where("start", ">=", stime.toDate().getTime()));
            const querySnapshot = await getDocs(q);
            const result = [];
            querySnapshot.docs.forEach((doc) => {
                const data = doc.data();
                if(!data.salesStaff) data.salesStaff = {};
                if(!data.eventType) data.eventType = {};
                if(!data.customer) data.customer = {};
                if(!data.celemony) data.celemony = {};
                const converted = {
                    "ID": doc.id,
                    "日付": moment(data.start).format('YYYY-MM-DD'),
                    "開始時刻": moment(data.start).format('HH:mm'),
                    "終了時刻": moment(data.end).format('HH:mm'),
                    "営業担当": data.salesStaff.name,
                    "受付日": moment(data.receipt).format('YYYY-MM-DD'),
                    "伝票No": data.slipNumber,
                    "区分": data.eventType.text,
                    "氏名": data.customer.name,
                    "フリガナ": data.customer.furigana,
                    "郵便番号": data.customer.postal,
                    "住所1": data.customer.address1,
                    "住所2": data.customer.address2,
                    "自宅TEL": data.customer.telHome,
                    "娘TEL": data.customer.telDaughter,
                    "代表TEL(母)": data.customer.telMother,
                    "代表TEL(父)": data.customer.telFather,
                    "年度": data.celemony.year,
                    "当日": data.celemony.onTheDay,
                    "当日詳細": data.celemony.ontheDayDetail,
                    "届け": data.celemony.todoke,
                    "はがき・LINE": data.celemony.hagaki,
                    "誰が送った？": data.celemony.hagakiSender,
                    "LINE登録": data.celemony.line,
                    "家族写真": data.celemony.familyPhoto,
                    "家族写真備考": data.celemony.familyPhotoMemo,
                    "備考": data.remarks,
                }
                result.push(converted);
            });
            return result;
        }
    },
    mutations: {
        select(state, { type, value, keyword, salesStaffId }) {
            if (type) state.type = type;
            if (value) state.value = value;
            if (keyword) {
                state.keyword = keyword;
            }
            else {
                state.keyword = '';
            }
            if (salesStaffId) {
                state.salesStaffId = salesStaffId;
            }
            else {
                state.salesStaffId = '';
            }
        },
        addEvent(state, event) {
            state.events.push(event);
        },
        modifyEvent(state, event) {
            const idx = state.events.findIndex(ev => ev.__id === event.__id);
            if(0 <= idx)
                Vue.set(state.events, idx, event);
        },
        removeEvent(state, event) {
            const idx = state.events.findIndex(ev => ev.__id === event.__id);
            //console.log("removed" + idx)
            if(0 <= idx)
                state.events.splice(idx, 1);
        },
        clearEvent(state) {
            state.events = [];
        },
        setUnscribe(state, unscribe) {
            state.unscribe = unscribe;
        },
        addReservation(state, event) {
            state.reservations.push(event);
        },
        modifyReservation(state, event) {
            const idx = state.reservations.findIndex(ev => ev.__id === event.__id);
            if(0 <= idx)
                Vue.set(state.reservations, idx, event);
        },
        removeReservation(state, event) {
            const idx = state.reservations.findIndex(ev => ev.__id === event.__id);
            if(0 <= idx) 
                state.reservations.splice(idx, 1);
        },
        clearReservation(state) {
            state.reservations = [];
        },
        setPlace(state, value) {
            state.place = value;
        },
    }
}