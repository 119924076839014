var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.events,"items-per-page":20},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toShortDateString")(item.start)))])]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toShortTimeString")(item.start)))])]}},{key:"item.place",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getPlace(item.place)))])]}},{key:"item.nameAndKana",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('ruby',[_c('rb',[_vm._v(_vm._s(item.customer.name))]),_c('rp',[_vm._v("（")]),_c('rt',[_vm._v(_vm._s(item.customer.furigana))]),_c('rp',[_vm._v("）")])],1)])]}},{key:"item.receiptDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toShortDateString")(item.receipt)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }