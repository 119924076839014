<template>
<v-sheet>
  <v-data-table
    :headers="headers"
    :items="events"
    :items-per-page="20"
    @click:row="rowClick"
    class="elevation-1"
  >
    <template v-slot:item.startDate="{ item }">
      <span>{{ item.start | toShortDateString }}</span>
    </template>
    <template v-slot:item.startTime="{ item }">
      <span>{{ item.start | toShortTimeString }}</span>
    </template>
    <template v-slot:item.receiptDate="{ item }">
      <span>{{ item.receipt | toShortDateString }}</span>
    </template>
    <template v-slot:item.nameAndKana="{ item }">
      <span
        ><ruby>
          <rb>{{item.customer.name}}</rb>
          <rp>（</rp>
          <rt>{{item.customer.furigana}}</rt>
          <rp>）</rp>
        </ruby></span
      >
    </template>
    <template v-slot:item.address="{ item }">
      <span>{{ item.customer.address1 }}{{ item.customer.address2 }}</span>
    </template>
    <template v-slot:item.tel="{ item }">
      <div>家 {{item.customer.telHome}}</div>
      <div>娘 {{item.customer.telDaughter}}</div>
      <div>母 {{item.customer.telMother}}</div>
    </template>
  </v-data-table>
</v-sheet>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    headers: [
      { text: "日付", value: "startDate" },
      { text: "時刻", value: "startTime" },
      { text: "受付日", value: "receiptDate"},
      { text: "区分", value: "eventType.abbr"},
      { text: "氏名", value: "nameAndKana" },
      { text: "郵便番号", value: "customer.postal" },
      { text: "住所", value: "address" },
      { text: "電話番号", value: "tel" },
      { text: "当日", value:"onTheDay"},
      { text: "届け", value:"todoke"},
    ]
  }),
  computed: {
    ...mapState("calendar", ["events"]),
  },
  methods: {
    rowClick(item) {
      this.$emit('rowClick', item);
    }
  }
};
</script>
