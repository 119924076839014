<template>
  <v-row class="flex-nowrap">
    <v-btn light depressed large class="ma-auto d-print-none" @click="today">今日</v-btn>
    <div class="d-flex flex-row mt-auto mb-auto ml-4 mr-4">
      <v-btn light depressed fab dark small color="primary" @click="movePrev" class="d-print-none">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <h2 class="ma-auto pl-4 pr-4 text-subtitle-1">{{ dateText }}</h2>
      <v-btn light depressed fab dark small color="primary" @click="moveNext" class="d-print-none">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <v-spacer></v-spacer>
    <h3 v-if="type !== 'day'" v-text="placeName" class="align-self-center mr-4"></h3>
    <v-text-field
      dense
      class="mt-auto mb-auto mr-4 d-print-none"
      solo
      light
      v-model="keyword"
      hide-details="auto"
      label="フリガナ or 電話番号（前方一致）"
      prepend-inner-icon="mdi-magnify"
      @keydown.enter="search"
      autocomplete="off"
    ></v-text-field>
    <v-btn-toggle class="d-print-none" v-model="typeIndex" light>
      <v-btn> 月 </v-btn>
      <v-btn> 週 </v-btn>
      <v-btn> 日 </v-btn>
      <v-btn> リスト </v-btn>
    </v-btn-toggle>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    keyword: "",
    salesStaff: null,
    calendarTypeList: [
      { id: 0, key: "month" },
      { id: 1, key: "week" },
      { id: 2, key: "day" },
      { id: 3, key: "list" },
    ],
  }),
  methods: {
    ...mapActions("visits", ["selectAsync"]),
    today() {
      this.selectAsync({ value: new Date(), keyword: "" });
    },
    moveNext() {
      this.move(+1);
    },
    movePrev() {
      this.move(-1);
    },
    move(offset) {
      switch (this.type) {
        case "month":
          this.selectAsync({
            value: moment(this.value).add(offset, "months").startOf().toDate(),
            keyword: "",
          });
          break;
        case "week":
          this.selectAsync({
            value: moment(this.value).add(offset, "weeks").toDate(),
            keyword: "",
          });
          break;
        case "day":
          this.selectAsync({
            value: moment(this.value).add(offset, "days").toDate(),
            keyword: "",
          });
          break;
      }
    },
    search() {
      var params = {};
      params.type = "list";
      if(this.keyword) params.keyword = this.keyword;
      if(this.salesStaff) params.salesStaffId = this.salesStaff.__id;
      this.selectAsync(params);
    },
  },
  computed: {
    ...mapState("visits", ["type", "value"]),
    ...mapState("users", {
      users: (state) => state.data.sort((a, b) => a.code - b.code),
    }),
    ...mapState("place", {places:'data'}),
    typeIndex: {
      get() {
        return this.calendarTypeList.filter(
          (n) => n.key == this.$store.state.visits.type
        )[0].id;
      },
      set(value) {
        this.selectAsync({
          type: this.calendarTypeList.filter((n) => n.id == value)[0].key,
        });
      },
    },
    placeName() {
      const p = this.places.find(p => p.id == this.$route.params.place);
      return p ? p.text : '';
    },
    dateText: {
      get() {
        switch (this.type) {
          case "month":
            return moment(this.value).format("YYYY年M月");
          case "week":
            //return moment(this.value).format("YYYY年第w週");
            return (
              moment(this.value).day(1).format("YYYY年M月D日(ddd)") +
              "〜" +
              moment(this.value).day(7).format("M月D日(ddd)")
            );
          case "day":
            return moment(this.value).format("YYYY年M月D日(ddd)");
          case "list":
            return moment(this.value).format("リスト");
          default:
            return "";
        }
      },
    },
  },
};
</script>
