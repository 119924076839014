<template>
  <v-dialog v-model="active" width="80%" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card v-if="event">
      <v-card-title class="text-h5"> 予約の編集 </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="overflow-y-auto" style="height: 70vh">
        <v-form>
          <v-row dense>
            <v-col>
              <date-picker v-model="event.start" label="日付" hide-details="auto"></date-picker>
            </v-col>
            <v-col>
              <time-picker v-model="event.start" label="開始時刻" font hide-details="auto"></time-picker>
            </v-col>
            <v-col>
              <time-picker v-model="event.end" label="終了時刻" hide-details="auto"></time-picker>
            </v-col>
            <v-col>
              <v-select :items="studios" label="スタジオ" item-text="abbr" v-model="event.category" return-object
                prepend-icon="mdi-form-select" hide-details="auto"></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col md="2">
              <v-select :items="users" label="営業担当" item-text="name" v-model="event.salesStaff" return-object
                prepend-icon="mdi-form-select" hide-details="auto"></v-select>
            </v-col>
            <v-col md="2">
              <v-select :items="users" label="接客者①" item-text="name" v-model="event.serviceStaff1" return-object
                prepend-icon="mdi-form-select" hide-details="auto"></v-select>
            </v-col>
            <v-col md="2">
              <v-select :items="users" label="接客者②" item-text="name" v-model="event.serviceStaff2" return-object
                prepend-icon="mdi-form-select" hide-details="auto"></v-select>
            </v-col>
            <v-col>
              <date-picker v-model="event.receipt" label="受付日" hide-details="auto" class="text-xs"></date-picker>
            </v-col>
            <v-col>
              <v-text-field v-model="event.slipNumber" label="伝票No." prepend-icon="mdi-alphabetical-variant"
                hide-details="auto" class="small"></v-text-field>
            </v-col>
            <v-col>
              <v-select :items="eventTypes" label="区分" v-model="event.eventType" return-object
                prepend-icon="mdi-form-select" hide-details="auto"></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field v-model="event.customer.name" label="氏名" prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="event.customer.furigana" label="フリガナ" prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col md="3">
              <v-select v-model="event.customer.isSet" :items="isSetItems" clearable label="セット完了"
                          prepend-icon="mdi-form-select" hide-details="auto"></v-select>
            </v-col>
            <v-col md="9">
              <v-text-field v-model="event.customer.album" label="アルバム販売" prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-expansion-panels accordion focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header>連絡先</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col md="3">
                        <v-text-field v-model="event.customer.postal" label="郵便番号" prepend-icon="mdi-numeric"
                          hide-details="auto" @input="getYubinBango"></v-text-field>
                      </v-col>
                      <v-col md="9">
                        <v-col cols="12">
                          <v-text-field v-model="event.customer.address1" label="住所1"
                            prepend-icon="mdi-syllabary-hiragana" hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="event.customer.address2" label="住所2"
                            prepend-icon="mdi-syllabary-hiragana" hide-details="auto"></v-text-field>
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="event.customer.telHome" label="自宅電話番号" prepend-icon="mdi-numeric"
                              hide-details="auto"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="event.customer.telDaughter" label="娘電話番号" prepend-icon="mdi-numeric"
                              hide-details="auto"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="event.customer.telMother" label="代表電話番号(母)" prepend-icon="mdi-numeric"
                              hide-details="auto"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="event.customer.telFather" label="代表電話番号(父)" prepend-icon="mdi-numeric"
                              hide-details="auto"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>成人式</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col md="5">
                        <v-select v-model="event.celemony.year" :items="years" label="年度" prepend-icon="mdi-form-select"
                          hide-details="auto"></v-select>
                      </v-col>
                      <v-col md="5" class="pa-4">
                        <v-chip v-if="wareki" color="primary">
                          {{ wareki }}</v-chip>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col md="3">
                        <v-select v-model="event.celemony.onTheDay" :items="onTheDays"
                          @change="event.celemony.preparationPlace = null; event.celemony.onTheDayTime = null" label="当日"
                          prepend-icon="mdi-form-select" hide-details="auto"></v-select>
                      </v-col>
                      <v-col md="5">
                        <v-select v-model="event.celemony.preparationPlace" label="場所" :items="places" item-text="name"
                          :clearable="true" @change="$forceUpdate(); event.celemony.onTheDayTime = null" item-value="id"
                          prepend-icon="mdi-form-select" hide-details="auto"></v-select>
                      </v-col>
                      <v-col md="4">
                        <v-select v-model="event.celemony.onTheDayTime" :items="onTheDayTimes" label="時間"
                          :clearable="true" prepend-icon="mdi-clock" hide-details="auto" append-outer-icon="mdi-magnify"
                          :disabled="(!event.celemony.preparationPlace)">
                          <template v-slot:append-outer>
                            <v-btn icon @click="showOnTheDayAvailability" :disabled="(!event.celemony.preparationPlace)">
                              <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col md="12">
                        <v-text-field v-model="event.celemony.ontheDayDetail" label="当日詳細"
                          prepend-icon="mdi-syllabary-hiragana" hide-details="auto"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select v-model="event.celemony.todoke" :items="todoke" label="届け"
                          prepend-icon="mdi-form-select" hide-details="auto"></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="event.celemony.hagaki" label="ハガキ・LINE" prepend-icon="mdi-calendar"
                          hide-details="auto" hint="送付済の場合日付を記入"></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="event.celemony.hagakiSender" label="誰が送った？"
                          prepend-icon="mdi-syllabary-hiragana" hide-details="auto" hint="送付担当者の氏名を記入"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select v-model="event.celemony.line" :items="line" label="LINE登録"
                          prepend-icon="mdi-form-select" hide-details="auto"></v-select>
                      </v-col>
                      <v-col>
                        <v-select v-model="event.celemony.familyPhoto" :items="familyPhotos" label="家族写真"
                          prepend-icon="mdi-form-select"></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="event.celemony.familyPhotoMemo" label="家族写真備考"
                          prepend-icon="mdi-syllabary-hiragana"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea label="備考" v-model="event.remarks" prepend-icon="mdi-syllabary-hiragana"></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" rounded @click="requestPassword"> 更新 </v-btn>
        <v-btn outlined rounded text @click="cancelEdit"> キャンセル </v-btn>
        <v-btn outlined rounded text @click="moveEvent" color="secondary" v-if="!movingMode">
          移動
        </v-btn>
        <v-btn outlined rounded text @click="reserve">
          「日付未定」へ移動
        </v-btn>
        <v-btn v-if="currentUser.role == 'admin'" outlined rounded text @click="showHistory">
          履歴
        </v-btn>
        <v-btn v-if="currentUser.role == 'admin'" color="error" rounded text @click="removeConfirmationDialog = true">
          削除
        </v-btn>
      </v-card-actions>
    </v-card>
    <password-dialog v-model="passwordDialog" @result="dialogResult">
    </password-dialog>
    <event-history-dialog v-model="historyDialog" :event="event"></event-history-dialog>

    <v-dialog v-model="removeConfirmationDialog" max-width="400">
      <v-card>
        <v-card-title>
          <div>削除確認</div>
        </v-card-title>
        <v-card-text>
          <p>削除すると元に戻せません。本当に削除しますか？</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="removeConfirmationDialog = false">キャンセル</v-btn>
          <v-btn @click="remove()" color="error">削除する</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 当日空き状況表示ダイアログ -->
    <v-dialog v-model="availabilityDialog" max-width="500">
      <v-card>
        <v-card-title>
          <div>当日空き状況({{ preparationPlaceName }})</div>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">時間</th>
                  <th class="text-right">件数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in availability" :key="item.time"
                  @click="event.celemony.onTheDayTime = item.value; availabilityDialog = false;">
                  <td>{{ item.time }}</td>
                  <td class="text-right">{{ item.count == 0 ? "" : item.count }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="availabilityDialog = false">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
import TimePicker from "../forms/TimePicker.vue";
import DatePicker from "../forms/DatePicker.vue";
import PasswordDialog from "./PasswordEntryDialog.vue";
import { mapActions, mapState } from "vuex";
import { Core as YubinBangoCore } from "yubinbango-core2";
import EventHistoryDialog from "./EventHistoryDialog.vue";
import { db } from "../../plugins/firebase";
import { query, getDocs, collection, where } from 'firebase/firestore';

export default {
  props: {
    value: {},
    event: null,
    preparationPlaces: [],
  },
  components: {
    TimePicker,
    DatePicker,
    PasswordDialog,
    EventHistoryDialog,
  },
  data: () => ({
    flg: true,
    selectedCustomer: null,
    onTheDays: [
      { text: "該当なし", value: 0 },
      { text: "有", value: 1 },
      { text: "美容", value: 2 },
      { text: "先方", value: 3 },
    ],
    familyPhotos: [
      { text: "あり", value: 0 },
      { text: "なし", value: 1 },
      { text: "要確認", value: 2 },
    ],
    todoke: [
      { text: "―", value: 2 },
      { text: "未", value: 0 },
      { text: "済", value: 1 },
      { text: "小物のみ済", value: 3 },
    ],
    line: [
      { text: "なし", value: 0 },
      { text: "本人のみ", value: 1 },
      { text: "保護者のみ", value: 2 },
      { text: "本人・保護者", value: 3 },
    ],
    isSetItems: [
      { text: "★", value: "★" },
    ],
    years: [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032],
    customers: [],
    customerSearch: "",
    customerLoading: false,
    originalEvent: null,
    passwordDialog: false,
    historyDialog: false,
    nameHistory: [],
    removeConfirmationDialog: false,
    onTheDayTimes: [],
    availability: [],
    availabilityDialog: false,
  }),
  mounted: function () {
    // onTheDayTimesを3:00〜10:30まで30分刻みの配列にする
    let time = new Date(2021, 1, 1, 3, 0, 0);
    while (time.getHours() < 11) {
      this.onTheDayTimes.push({
        text: time.toLocaleTimeString("ja-JP", {
          hour: "2-digit",
          minute: "2-digit",
        }),
        value: time.getHours() * 100 + time.getMinutes(),
      }
      );
      time.setMinutes(time.getMinutes() + 30);
    }
    // console.log(this.onTheDayTimes)
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState(["movingMode"]),
    ...mapState("users", {
      users: (state) => state.data.slice().sort((a, b) => a.code - b.code),
    }),
    ...mapState("studio", { studios: "data" }),
    ...mapState("eventTypes", {
      eventTypes: (state) =>
        state.data.slice().sort((a, b) => a.value - b.value),
    }),
    active: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    wareki: function () {
      if (this.event.celemony.year) {
        const dateText = String(this.event.celemony.year) + "-01-01";
        const dateValue = new Date(dateText);
        const result = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
          era: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(dateValue);
        return result.substring(0, result.indexOf("年") + 1);
      } else {
        return "";
      }
    },
    places: {
      // 当日を条件として準備場所から抽出した配列を返す
      get() {
        // console.log(this.preparationPlaces)
        if(this.preparationPlaces) {
          const list = this.preparationPlaces.filter(
          (place) => place.onTheDay == this.event.celemony.onTheDay
        );
        console.log(list);
        list.sort((a, b) => a.order - b.order);
        return list;
        } else {
          return [];
        }
      },
    },
    preparationPlaceName: {
      // 準備場所IDから準備場所名称を返す
      get() {
        if (this.event.celemony.preparationPlace) {
          const place = this.preparationPlaces.find(
            (place) => place.id == this.event.celemony.preparationPlace
          );
          return place?.name;
        } else {
          return "";
        }
      },
    },
  },
  methods: {
    ...mapActions(["startMove"]),
    ...mapActions("calendar", ["reserveAsync", "removeAsync"]),
    getYubinBango(input) {
      if (input.length == 7) {
        // console.log(input);
        new YubinBangoCore(input, (addr) => {
          this.event.customer.address1 = addr.region; // 都道府県
          this.event.customer.address1 += addr.locality; // 市区町村
          this.event.customer.address1 += addr.street; // 町域
          this.event = Object.assign({}, this.event);
        });
      }
    },
    moveEvent() {
      this.startMove({ move: true, event: this.event });
      this.$emit("move", this.event);
      this.active = false;
    },
    requestPassword() {
      this.passwordDialog = true;
    },
    dialogResult(result) {
      if (result.result == "ok") {
        this.event.updatedBy = result.user;
        this.endEdit();
      }
    },
    endEdit() {
      if (this.event.eventType) {
        this.event.color = this.event.eventType.color;
      }
      this.event.studio = this.event.category ? this.event.category.id : null;
      this.$emit("ok", this.event);
      this.active = false;
    },
    cancelEdit() {
      this.$emit("cancel", this.event);
      this.active = false;
    },
    showHistory() {
      this.historyDialog = true;
    },
    async reserve() {
      await this.reserveAsync(this.event);
      this.$emit("reserve", this.event);
      this.active = false;
    },
    async remove() {
      this.removeAsync(this.event);
      this.active = false;
    },
    async showOnTheDayAvailability() {
      // Firestoreから年度、準備場所が同じレコードを抽出する
      // console.log({year:this.event.celemony.year, preparationPlace:this.event.celemony.preparationPlace})
      const availability = [];
      if (this.event.celemony.year && this.event.celemony.preparationPlace) {
        const querySnapshot = await getDocs(
          query(
            collection(db, "events"),
            where("celemony.year", "==", this.event.celemony.year),
            where("celemony.preparationPlace", "==", this.event.celemony.preparationPlace)
          )
        );

        // 抽出したデータから当日時間帯ごとの件数を集計する
        for (let i = 0; i < this.onTheDayTimes.length; i++) {
          availability.push({
            time: this.onTheDayTimes[i].text,
            value: this.onTheDayTimes[i].value,
            count: 0,
          });
        }
        querySnapshot.forEach((doc) => {
          const event = doc.data();
          // console.log(event)
          const index = availability.findIndex(
            (item) => item.value == event.celemony.onTheDayTime
          );
          if (index >= 0) {
            availability[index].count++;
          }
        });

        this.availability = availability;
      }
      this.availabilityDialog = true;
    }
  },
};
</script>
