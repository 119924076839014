/* eslint-disable no-unused-vars */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator, doc } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectAuthEmulator, getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBiGXBABEE8T-lxZ3T5AkkylOaRnMHBXDQ",
  authDomain: "fine-reserve.firebaseapp.com",
  projectId: "fine-reserve",
  storageBucket: "fine-reserve.appspot.com",
  messagingSenderId: "444412429279",
  appId: "1:444412429279:web:333bfc648d1eae7004ba6a",
  measurementId: "G-14K4H3WDMQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();
const db = getFirestore();
const functions = getFunctions(app, 'asia-northeast1');

console.log({env:process.env.VUE_APP_USE_EMULATOR})

if(process.env.VUE_APP_USE_EMULATOR === 'YES') {
  console.log('Using Firebase Emulator')
  connectFirestoreEmulator(db, 'localhost', 9802);
  connectAuthEmulator(auth, 'http://localhost:9801')
  connectFunctionsEmulator(functions, 'localhost', 5001);
}
 
export  {auth, db, functions}