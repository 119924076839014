<template>
    <v-container>
        <h1>成人式当日確認</h1>
        <v-row>
            <v-col>
                <v-select label="年度" v-model="nendo" :items="nendos" item-value="value" item-text="text" hide-details
                    outlined></v-select>
            </v-col>
            <v-col>
                <v-select label="当日" v-model="onTheDay" :items="onTheDays" item-value="value" item-text="text" hide-details
                    outlined></v-select>
            </v-col>
            <v-col>
                <v-select label="準備場所" v-model="placeId" :items="places" item-text="name" item-value="id" hide-details
                    outlined></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-tabs v-model="tab">
                    <v-tab key="list">リスト</v-tab>
                    <v-tab key="matrix">時間別件数</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item key="list">
                        <v-data-table :headers="listHeaders" :items="listData" :items-per-page="10" item-key="id"
                            @click:row="(e) => showDialog(e)" fixed-header height="60dvh">
                            <template v-slot:item.tel="{ item }">
                                自宅:{{ item.tel1 }}<br />
                                娘:{{ item.tel2 }}<br />
                                母:{{ item.tel3 }}<br />
                            </template>
                        </v-data-table>
                        <div style="text-align:right">
                            <download-excel class="export-excel-wrapper" :fetch="getExcel" type="xls" worksheet="成人式当日"
                                name="成人式当日">
                                <v-btn color="primary" text>ダウンロード</v-btn>
                            </download-excel>
                        </div>
                    </v-tab-item>
                    <v-tab-item key="matrix">
                        <v-card color="basil" flat>
                            <v-data-table :headers="timeListHeaders" :items="timeGroupData" :items-per-page="10"
                                item-key="id" @click:row="(e) => showDialog(e)" fixed-header height="60dvh">

                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>

        <event-edit-dialog v-if="eventDialog" v-model="eventDialog" :event="selectedEvent" :preparationPlaces="places"
            @ok="update" @cancel="cancel"></event-edit-dialog>
    </v-container>
</template>
<script>
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../plugins/firebase';
import { mapActions } from "vuex";
import moment from "moment";
import EventEditDialog from "../components/dialog/EventEditDialog.vue";

export default {
    components: {
        EventEditDialog,
    },
    data: () => ({
        tab: 'list',
        nendo: '',
        nendos: [
            { text: '令和6年度', value: 2024 },
            { text: '令和7年度', value: 2025 },
            { text: '令和8年度', value: 2026 },
            { text: '令和9年度', value: 2027 },
            { text: '令和10年度', value: 2028 },
            { text: '令和11年度', value: 2029 },
            { text: '令和12年度', value: 2030 },
            { text: '令和13年度', value: 2031 },
            { text: '令和14年度', value: 2032 },
        ],
        loading: false,
        events: [],
        placeId: null,
        places: [],
        listHeaders: [
            { text: '時間', value: 'onTheDayTime' },
            { text: '契約日', value: 'receipt' },
            { text: '伝票No.', value: 'slipNumber' },
            { text: '区分', value: 'eventType' },
            { text: '氏名', value: 'customer' },
            { text: '住所', value: 'address' },
            { text: '電話番号', value: 'tel' },
            { text: '担当', value: 'staff' },
            { text: '当日備考', value: 'onTheDayDetail' },
        ],
        timeListHeaders: [
            { text: '時間', value: 'onTheDayTime' },
            { text: '件数', value: 'count' },
        ],
        eventDialog: false,
        selectedEvent: null,
        onTheDay: -1,
        onTheDays: [
            { text: "未設定", value: -1 },
            { text: "該当なし", value: 0 },
            { text: "有", value: 1 },
            { text: "美容", value: 2 },
            { text: "先方", value: 3 },
        ],
    }),
    mounted: async function () {
        this.places = (await getDocs(collection(db, 'preparationPlaces'))).docs.map(doc => ({ id: doc.id, ...doc.data() })).sort((a, b) => a.order - b.order)
        this.places.unshift({ id: 0, name: '未設定' });

        // 年度の初期値に翌年をセットする
        this.nendo = this.nendos.filter(n => n.value >= moment().year() + 1)[0].value;
        this.placeId = 0;
    },
    computed: {
        listData: {
            get() {
                return this.events.map((event) => {
                    return {
                        item: { ...event },
                        onTheDayTime: this.toTimeString(event.celemony.onTheDayTime),
                        receipt: moment(event.receipt).format('L'),
                        eventType: event.eventType ? event.eventType.text : "",
                        customer: event.customer.name,
                        address: event.customer.address1 + event.customer.address2,
                        tel1: event.customer.telHome,
                        tel2: event.customer.telDaughter,
                        tel3: event.customer.telMother,
                        staff: event.salesStaff ? event.salesStaff.name : "",
                        onTheDayDetail: event.celemony.ontheDayDetail
                    }
                })
            }
        },
        timeGroupData: {
            get() {
                return this.events.reduce((acc, cur) => {
                    const element = acc.find(value => value.onTheDayTime === cur.celemony.onTheDayTime);
                    if (element) {
                        element.items.push(cur)
                    }
                    else {
                        acc.push({
                            onTheDayTime: cur.celemony.onTheDayTime,
                            items: [cur]
                        })
                    }
                    return acc;
                }, []).sort((a, b) => a.onTheDayTime - b.onTheDayTime).map((event) => {
                    return {
                        onTheDayTime: this.toTimeString(event.onTheDayTime),
                        count: event.items.length
                    }
                })
            }
        },
        list: {
            get() {
                return this.events.reduce((acc, cur) => {
                    const element = acc.find(value => value.onTheDayTime === cur.celemony.onTheDayTime);
                    if (element) {
                        element.items.push(cur)
                    }
                    else {
                        acc.push({
                            onTheDayTime: cur.celemony.onTheDayTime,
                            items: [cur]
                        })
                    }
                    return acc;
                }, []).sort((a, b) => a.onTheDayTime - b.onTheDayTime)
            }
        }
    },
    watch: {
        nendo: async function () {
            await this.load();
        },
        onTheDay: async function () {
            await this.load();
        },
        placeId: async function () {
            await this.load();
        }
    },
    methods: {
        ...mapActions("calendar", ["updateAsync"]),
        getExcel() {
            return this.events.map((event) => {
                return {
                    "時間": this.toTimeString(event.celemony.onTheDayTime),
                    "契約日": moment(event.receipt).format('L'),
                    "伝票No.": event.slipNumber,
                    "区分": event.eventType ? event.eventType.text : "",
                    "氏名": event.customer.name,
                    "住所": event.customer.address1 + event.customer.address2,
                    "電話番号": "自宅:" + event.customer.telHome + " 娘:" + event.customer.telDaughter + " 母:" + event.customer.telMother,
                    "担当": event.salesStaff ? event.salesStaff.name : "",
                    "当日備考": event.celemony.ontheDayDetail
                }
            })
        },
        async load() {
            if (this.loading) return;
            this.loading = true;

            this.events = [];
            if (this.nendo !== '') {
                // Firestoreから選択された年度と準備場所のeventデータを取得する
                let q = query(collection(db, 'events'), where('celemony.year', '==', this.nendo))
                //q = query(q, where('celemony.onTheDay', 'in', [1, 2]))
                if (this.onTheDay !== -1) {
                    q = query(q, where('celemony.onTheDay', '==', this.onTheDay))
                }
                if (this.placeId != 0) {
                    q = query(q, where('celemony.preparationPlace', '==', this.placeId))
                }
                const querySnapshot = await getDocs(q);
                querySnapshot.docs.forEach((doc) => {
                    this.events.push({ id: doc.id, __id: doc.id, ...doc.data() })
                });
            }

            this.loading = false;
        },
        showDialog(row) {
            console.log(row)
            this.selectedEvent = row.item;
            this.eventDialog = true;
        },
        async update() {
            this.updateAsync(this.selectedEvent);
            this.eventDialog = false;
        },
        cancel() {
            this.eventDialog = false;
        },
        formatReceipt(value) {
            return moment(value).format('L');
        },
        toTimeString(value) {
            if (value) {
                // ゼロで埋めて4桁にする
                var str = ('0000' + value).slice(-4);

                // 2文字目と3文字目にコロンを挿入する
                str = str.slice(0, 2) + ':' + str.slice(2, 4);

                // 先頭の0を削除する
                return str.replace(/^0+/, '');
            } else {
                return 'ー';
            }
        },
    },
};
</script>