import Vue from "vue";
import moment from 'moment';

Vue.filter('toShortDateString', function (value) {
    return moment(value).format('YYYY-MM-DD');
});

Vue.filter('toShortTimeString', function (value) {
    return moment(value).format('HH:mm');
});

Vue.filter('toDateTimeString', function (value) {
    return moment(value).format('YYYY-MM-DD HH:mm');
});