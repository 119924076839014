<template>
  <v-sheet>
    <v-list>
      <v-subheader>利用者メニュー</v-subheader>
      <v-divider />
      <v-subheader>予約</v-subheader>
      <v-list-item v-for="s in studios" :key="s.id" link :to="`/reservation/${s.id}`">
        <v-list-item-icon><v-icon>mdi-domain</v-icon></v-list-item-icon>
        <v-list-item-title>{{ s.text }}</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/freetime" replace>
        <v-list-item-icon><v-icon>mdi-magnify</v-icon></v-list-item-icon>
        <v-list-item-title>空き時間検索</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-divider />
      <v-subheader>来店予定</v-subheader>
      <v-list-item v-for="p in places" :key="'p' + p.id.toString()" link :to="`/visitation/${p.id}`">
        <v-list-item-icon><v-icon>mdi-domain</v-icon></v-list-item-icon>
        <v-list-item-title>{{ p.text }}</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-subheader>その他</v-subheader>
      <v-list-item link to="/box" replace>
        <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
        <v-list-item-title>日付未定</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/download" replace>
        <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
        <v-list-item-title>ダウンロード</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-sheet v-if="currentUser.role == 'admin'">
        <v-subheader>管理者メニュー</v-subheader>
        <v-list-item link to="/celemony" replace>
          <v-list-item-icon><v-icon>mdi-list-box-outline</v-icon></v-list-item-icon>
          <v-list-item-title>成人式当日</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/user" replace>
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <v-list-item-title>ユーザー</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/event-type" replace>
          <v-list-item-icon><v-icon>mdi-shape-outline</v-icon></v-list-item-icon>
          <v-list-item-title>区分</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/studio" replace>
          <v-list-item-icon><v-icon>mdi-shape-outline</v-icon></v-list-item-icon>
          <v-list-item-title>スタジオ</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/place" replace>
          <v-list-item-icon><v-icon>mdi-shape-outline</v-icon></v-list-item-icon>
          <v-list-item-title>来店場所</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/preparationPlace" replace>
          <v-list-item-icon><v-icon>mdi-shape-outline</v-icon></v-list-item-icon>
          <v-list-item-title>準備場所</v-list-item-title>
        </v-list-item>
        <!--<v-list-item link to="/other" replace>
        <v-list-item-icon><v-icon>mdi-cog-outline</v-icon></v-list-item-icon>
        <v-list-item-title>その他</v-list-item-title>
      </v-list-item>-->
      </v-sheet>
      <v-list-item link @click="logout" replace>
        <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
        <v-list-item-title>ログアウト</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-sheet>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions("auth", ["logoutAsync"]),
    async logout() {
      await this.logoutAsync();
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState("studio", { studios: 'data' }),
    ...mapState("place", { places: 'data' })
  }
};
</script>
