<template>
  <v-row>
    <v-col class="col-4">
      <v-sheet v-if="holiday != ''" style="background-color:transparent">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red" size="small" v-bind="attrs" v-on="on">
              mdi-flag
            </v-icon>
          </template>
          <span>{{ holiday }}</span>
        </v-tooltip>
      </v-sheet>
    </v-col>
    <v-col class="col-4">
      <v-btn @click="dateClick(value)" fab x-small depressed :color="buttonColor">{{ day }}</v-btn>
    </v-col>
    <v-col class="col-4">
      <span
        style="font-size: 0.2rem"
        class="text--second"
        v-bind:class="{
          'red--text': rokuyo == '大安',
        }"
        >{{ rokuyo }}</span
      >
    </v-col>
  </v-row>
</template>

<script>
import qreki from "../../plugins/qreki";

export default {
  props: {
    value: Date,
  },
  data: () => ({
      koyomi:qreki,
  }),
  computed: {
    year: function () {
      return this.value.getFullYear();
    },
    month: function () {
      return this.value.getMonth() + 1;
    },
    day: function () {
      return this.value.getDate();
    },
    weekDay: function() {
        return this.value.getDay();
    },
    holiday: function () {
      return this.koyomi[this.year][this.month][this.day].holiday;
    },
    rokuyo: function () {
      return this.koyomi[this.year][this.month][this.day].rokuyo;
    },
    buttonColor: function () {
        if(this.holiday != "") {
            return "#FFB6C1";
        }
        else if(this.weekDay == 6) {
            return "#87CEFA";
        }
        else if(this.weekDay == 0) {
            return "#FFB6C1";
        }
        else {
            return "lightgray";
        }
    },
  },
  methods: {
    dateClick() {
      this.$emit('buttonClick', this.value);
    },
  },
};
</script>
