
<template>
  <!-- v-bind:isでタブインタフェースのコンポーネントを切り替えられる -->
  <component v-bind:is="layout()"></component>
</template>

<script>
import { mapMutations } from "vuex";
import Default from "./layouts/Default.vue";
import Login from "./layouts/Login.vue";
import { db } from "./plugins/firebase";
import { getDocs, collection } from 'firebase/firestore';

export default {
  components: { Default, Login },
  name: "App",
  created: async function () {
    // 準備場所コレクションが存在しないか空だったら初期データを作成する
    // 準備場所はプロパティとして名称、略名、当日区分（なし、あり、美容あり）を持つ
    const preparationPlacesDocs = await getDocs(collection(db, "preparationPlaces"))
    const preparationPlaces = preparationPlacesDocs.docs.map((doc) => doc.data());
    this.setPreparationPlaces(preparationPlaces);
  },
  methods: {
    ...mapMutations(["setPreparationPlaces"]),
    layout() {
      // router.jsのroutesにmeta.layoutの存在確認
      // セットするレイアウトを判断する
      let layout = this.$route.meta.layout
        ? this.$route.meta.layout
        : "Default";
      return layout;
    },
  },
};
</script>
