<template>
  <span :class="(value.kariyotei ? 'black--text' : '')" style="display:inline-block;white-space:normal;line-height:1.0rem;">{{ name }}</span>
</template>
<style scoped>
</style>
<script>
import moment from "moment";

export default {
  props: {
    value: Object,
    mode: String,
  },
  computed: {
    name: {
      get() {
        var result = "";
        // 時刻
        result = result + moment(this.value.start).format("HH:mm");

        // 区分
        if (this.value.kubun && this.value.kubun.text) {
          result = result + " " + this.value.kubun.text;
        }

        // 顧客
        if (this.value.customer && this.value.customer.name) {
          result = result + " " + this.value.customer.name;
        }

        // 市町村
        if(this.value.city) {
          result = result + " " + this.value.city;
        }

        // 年度
        if(this.value.celemony && this.value.celemony.year) {
          result = result + " " + this.wareki(this.value.celemony.year);
        }

        // 受付担当
        if (this.value.salesStaff) {
          result = result + " (" + this.value.salesStaff.name + ")";
        }

        // 日・または週表示
        if (this.value.remarks && (this.mode == "day" || this.mode == "week")) {
          result = result + ' | ' + this.value.remarks;
        }

        return result;
      },
    },
  },
  methods: {
    wareki: function (year) {
      if (year) {
        const dateText = String(year) + "-01-01";
        const dateValue = new Date(dateText);
        let result = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
          era: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(dateValue);
        result = result.replace('令和', 'R')
        return result.substring(0, result.indexOf("年"));
      } else {
        return "";
      }
    },
  }
};
</script>