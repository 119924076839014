<template>
  <v-dialog v-model="active" width="400">
    <v-card class="pa-8"
      ><v-row>
        <v-col>
          <v-text-field
            label="担当者専用パスワード"
            v-model="password"
            :type="'password'"
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-alert
            :value="invalidPassword"
            type="error"
            transition="fade-transition"
          >
            パスワードが一致する担当者は登録されていません。
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary" @click="validate">認証</v-btn>
        </v-col>
        <v-col>
          <v-btn color="secondary" @click="cancel">キャンセル</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {},
  },
  data: () => ({
    password: "",
    invalidPassword: false,
  }),
  computed: {
    ...mapGetters("users", ["getUserFromPassword"]),
    active: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  watch: {
    active: function(val) {
      if(!val) {
        this.password = "";
        this.invalidPassword = false;
      }
    }
  },
  methods: {
    validate() {
      const user = this.getUserFromPassword(this.password);
      if (user != null) {
        this.invalidPassword = false;
        this.$emit("result", { result: "ok", user: user });
        this.active = false;
      } else {
        this.invalidPassword = true;
      }
    },
    cancel() {
      this.$emit("result", { result: "cancel" });
      this.active = false;
    },
  },
};
</script>