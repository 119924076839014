<template>
  <v-sheet transition="slide-y-transition">
    <v-card dense v-if="selectedReservations.length > 0" class="elevation-0">
      <v-card-text class="pa-2">
        <v-row dense>
          <v-col cols="12" sm="6" class="d-flex align-center">
            <span class="d-inline-block mr-3">選択した予約を</span>
            <v-btn small color="error" @click="deleteWarning = true">削除する</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table v-model="selectedReservations" :headers="headers" :items="reservations" :items-per-page="20"
      item-key="__id" show-select class="elevation-1">
      <template v-slot:item.startDate="{ item }">
        <span>{{ item.start | toShortDateString }}</span>
      </template>
      <template v-slot:item.startTime="{ item }">
        <span>{{ item.start | toShortTimeString }}</span>
      </template>
      <template v-slot:item.receiptDate="{ item }">
        <span>{{ item.receipt | toShortDateString }}</span>
      </template>
      <template v-slot:item.nameAndKana="{ item }">
        <span><ruby>
            <rb>{{ item.customer.name }}</rb>
            <rp>（</rp>
            <rt>{{ item.customer.furigana }}</rt>
            <rp>）</rp>
          </ruby></span>
      </template>
      <template v-slot:item.address="{ item }">
        <span>{{ item.customer.address1 }}{{ item.customer.address2 }}</span>
      </template>
      <template v-slot:item.tel="{ item }">
        <div>家 {{ item.customer.telHome }}</div>
        <div>娘 {{ item.customer.telDaughter }}</div>
        <div>母 {{ item.customer.telMother }}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small @click="rowClick(item)" color="secondary"><v-icon small>
            mdi-open-in-new
          </v-icon>
          詳細</v-btn>
      </template>
    </v-data-table>

    <event-edit-dialog v-if="eventDialog" v-model="eventDialog" :event="selectedEvent" @ok="update"></event-edit-dialog>

    <v-dialog v-model="deleteWarning">
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <p>選択したイベントを削除します。</p>
              <p class="red--text">この操作は復元できません。</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="deleteWarning = false">キャンセル</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deleteSelected">削除する</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EventEditDialog from "../components/dialog/EventEditDialog.vue";

export default {
  components: {
    EventEditDialog,
  },
  data: () => ({
    headers: [
      { text: "受付日", value: "receiptDate" },
      { text: "区分", value: "eventType.abbr" },
      { text: "氏名", value: "nameAndKana" },
      { text: "郵便番号", value: "customer.postal" },
      { text: "住所", value: "address" },
      { text: "電話番号", value: "tel" },
      { text: "当日", value: "onTheDay" },
      { text: "届け", value: "todoke" },
      { text: "", value: "actions" },
    ],
    eventDialog: false,
    selectedEvent: null,
    selectedReservations: [],
    deleteWarning: false,
  }),
  mounted: function () {
    this.selectReserveAsync();
  },
  computed: {
    ...mapState("calendar", ["reservations"]),
  },
  methods: {
    ...mapActions("calendar", ["selectReserveAsync", "updateAsync", "removeAsync"]),
    rowClick(item) {
      this.selectedEvent = item;
      this.eventDialog = true;
    },
    deleteSelected() {
      this.selectedReservations.forEach((item) => {
        this.removeAsync(item);
      });
      this.deleteWarning = false;
    },
    async update(event) {
      await this.updateAsync(event);
    },
  }
};
</script>
