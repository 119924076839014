<template>
    <v-container fluid>
        <v-row>
            <v-col class="d-flex flex-row">
                <v-btn color="primary" class="mt-2" @click="prevMonth">前月
                </v-btn>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="month"
                    transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="displayDate" label="対象月" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                            class="shrink mx-4" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="month" type="month" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                            キャンセル
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(month)">
                            決定
                        </v-btn>
                    </v-date-picker>
                </v-menu>
                <v-btn color="primary" class="mt-2" @click="nextMonth">次月
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <p>※土日祝の空き時間のみ表示します。</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div v-if="loading" class="d-flex justify-center align-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
                <div v-else>
                    <v-data-table :headers="headers" :items="vacancies" :items-per-page="10" class="elevation-1">
                        <template #item.action="{item}">
                            <v-btn color="primary" @click="reserve(item)">移動</v-btn>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>

        <event-edit-dialog v-if="dialog" v-model="dialog" :event="newEvent" @ok="update"></event-edit-dialog>
    </v-container>
</template>

<script>
import { collection, query, where, getDocs } from 'firebase/firestore';
import moment from 'moment';
import { db } from '../plugins/firebase';
import qreki from "../plugins/qreki";
import { mapActions } from 'vuex';
import EventEditDialog from "../components/dialog/EventEditDialog.vue";

export default {
    components: {
        EventEditDialog
    },
    data: () => ({
        headers: [
            { text: '日付', value: 'dateString' },
            { text: '', value: 'action' }
        ],
        items: [],
        menu: false,
        month: moment().format("YYYY-MM"),
        loading: false,
        unscribe: null,
        newEvent: null,
        dialog: false,
    }),
    computed: {
        displayDate() {
            return moment(this.month).format('YYYY年MM月')
        },
        vacancies() {
            return this.items.filter(c => c.count == 0);
        }
    },
    watch: {
        month: function () {
            this.load()
        }
    },
    mounted: async function(){
        await this.load();
    },
    methods: {
        ...mapActions("calendar", ["updateAsync"]),
        nextMonth() {
            this.month = moment(this.month).add(1, 'months').format("YYYY-MM")
        },
        prevMonth() {
            this.month = moment(this.month).add(-1, 'months').format("YYYY-MM")
        },
        async load() {
            this.loading = true

            // 月初・月末を算出
            const start = moment(this.month).startOf('month').toDate();
            const end = moment(this.month).endOf('month').toDate();

            // 開始時刻、終了時刻、枠を設定
            const sHour = 10; // 10時から
            const eHour = 15; // 15時まで
            const waku = 50; // 50分ごと

            // 1ヶ月分の枠リストを生成
            const times = []
            let d = moment(start)
            while (d.isBefore(end)) {
                // 土日祝のみ対象
                if (qreki[d.year()][d.month() + 1][d.date()].holiday != "" || d.day() == 0 || d.day() == 6) {
                    let t = d.clone().add(sHour, 'hours')
                    const last = d.clone().add(eHour, 'hours').add(waku, 'minutes')
                    while (t < last) {
                        const et = t.clone().add(waku, 'minutes');
                        times.push({
                            date: d.toDate(),
                            dateString: t.format('YYYY年MM月DD日(ddd) H:mm') + ' 〜 ' + et.format('H:mm'),
                            start: t.clone(),
                            end: et,
                            count: 0
                        })
                        t.add(waku, 'minutes')
                    }
                }

                // 翌日へ
                d.add(1, 'days')
            }

            // 該当月の空き枠を削除していくためのクエリを作成
            var q = query(collection(db, 'events'));
            q = query(q, where("start", ">=", start.getTime()));
            q = query(q, where("start", "<=", end.getTime()));

            var querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                const data = doc.data()
                const ds = moment(data.start);
                const de = moment(data.end);
                times.filter((s) => (ds <= s.start && s.start < de) || (ds < s.end && s.end <= de) || (s.start < ds && de < s.end)).forEach(tm => {
                    tm.count += 1
                });
            })

            // 結果をセットする
            this.items = times;

            // ロードを初期化
            this.loading = false
        },
        reserve(tm) {
            this.$router.push("reservation/1/" + tm.start.format('YYYY-MM-DD'));
            /*
            this.newEvent = {
                name: `Event #1`,
                color: "#666666",
                start: tm.start.toDate().getTime(),
                end: tm.end.toDate().getTime(),
                receipt: moment(new Date()).unix() * 1000,
                timed: true,
                customer: {},
                category: {id:1, abbr:'撮影予定'},
                studio: 1,
                celemony: {
                    onTheDay: 0,
                    todoke: 2,
                    familyPhoto: 0,
                },
            }
            this.dialog = true;
            */
        },
        async update() {
            await this.updateAsync(this.newEvent);
            await this.load();
        }
    }
}
</script>