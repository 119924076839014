<template>
  <v-app>
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Fine Reserve ログイン</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="email"
                    prepend-icon="mdi-account"
                    label="メールアドレス"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    id="password"
                    prepend-icon="mdi-lock"
                    label="パスワード"
                    type="password"
                  ></v-text-field>
                </v-form>
                <v-alert v-if="message != ''" type="error">{{
                  message
                }}</v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login">ログイン</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  data: () => ({
    email: "",
    password: "",
    message: "",
  }),
  props: {
    source: String,
  },
  methods: {
    ...mapActions("auth", ["loginAsync"]),

    // ログイン
    login() {
      //console.log(this.email);
      this.loginAsync({ email: this.email, password: this.password })
        .then((result) => {
          if (result) {
            this.message = "ログインしました";
            this.$router.push("/");
          } else {
            this.message = "メールアドレス、またはパスワードが間違っています。";
          }
        })
        .catch((err) => {
          this.message = err;
        });
    },
  },
};
</script>
