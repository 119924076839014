<template>
  <v-dialog v-model="active" width="60%">
    <v-card v-if="event">
      <v-card-title class="text-h5">
        履歴:{{ event.updatedText }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form>
          <v-row dense>
            <v-col>
              <v-text-field
                v-bind:value="event.start | toShortDateString"
                label="日付"
                hide-details="auto"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-bind:value="event.start | toShortTimeString"
                label="開始時刻"
                hide-details="auto"
                prepend-icon="mdi-clock-time-four-outline"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-bind:value="event.end | toShortTimeString"
                label="終了時刻"
                hide-details="auto"
                prepend-icon="mdi-clock-time-four-outline"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="event.customer.name"
                label="氏名"
                prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="event.customer.furigana"
                label="フリガナ"
                prepend-icon="mdi-syllabary-hiragana"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="event.eventType.text"
                label="区分"
                prepend-icon="mdi-form-select"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="event.salesStaff.name"
                label="営業担当"
                prepend-icon="mdi-form-select"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="event.slipNumber"
                label="伝票No."
                prepend-icon="mdi-alphabetical-variant"
                hide-details="auto"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-expansion-panels accordion focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header>成人式</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col md="5">
                        <v-select
                          v-model="event.celemony.year"
                          :items="years"
                          label="年度"
                          prepend-icon="mdi-form-select"
                          hide-details="auto"
                          readonly
                        ></v-select>
                      </v-col>
                      <v-col md="5" class="pa-4">
                        <v-chip v-if="wareki" color="primary">{{
                          wareki
                        }}</v-chip>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col md="3">
                        <v-select
                          v-model="event.celemony.onTheDay"
                          :items="onTheDays"
                          label="当日"
                          prepend-icon="mdi-form-select"
                          hide-details="auto"
                          readonly
                        ></v-select>
                      </v-col>
                      <v-col md="9">
                        <v-text-field
                          v-model="event.celemony.ontheDayDetail"
                          label="当日詳細"
                          prepend-icon="mdi-syllabary-hiragana"
                          hide-details="auto"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="event.celemony.todoke"
                          :items="todoke"
                          label="届け"
                          prepend-icon="mdi-form-select"
                          hide-details="auto"
                          readonly
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="event.celemony.hagaki"
                          label="ハガキ"
                          prepend-icon="mdi-calendar"
                          hide-details="auto"
                          hint="送付済の場合日付を記入"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="event.celemony.hagakiSender"
                          label="誰が送った？"
                          prepend-icon="mdi-syllabary-hiragana"
                          hide-details="auto"
                          hint="送付担当者の氏名を記入"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="event.celemony.familyPhoto"
                          :items="familyPhotos"
                          label="家族写真"
                          prepend-icon="mdi-form-select"
                          readonly
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="event.celemony.familyPhotoMemo"
                          label="家族写真備考"
                          prepend-icon="mdi-syllabary-hiragana"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>連絡先</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col md="3">
                        <v-text-field
                          v-model="event.customer.postal"
                          label="郵便番号"
                          prepend-icon="mdi-numeric"
                          hide-details="auto"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col md="9">
                        <v-col cols="12">
                          <v-text-field
                            v-model="event.customer.address1"
                            label="住所1"
                            prepend-icon="mdi-syllabary-hiragana"
                            hide-details="auto"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="event.customer.address2"
                            label="住所2"
                            prepend-icon="mdi-syllabary-hiragana"
                            hide-details="auto"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="event.customer.telHome"
                              label="自宅電話番号"
                              prepend-icon="mdi-numeric"
                              hide-details="auto"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="event.customer.telFather"
                              label="代表電話番号(父)"
                              prepend-icon="mdi-numeric"
                              hide-details="auto"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="event.customer.telMother"
                              label="代表電話番号(母)"
                              prepend-icon="mdi-numeric"
                              hide-details="auto"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            ><v-text-field
                              v-model="event.customer.telDaughter"
                              label="娘電話番号"
                              prepend-icon="mdi-numeric"
                              hide-details="auto"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="備考"
                v-model="event.remarks"
                prepend-icon="mdi-syllabary-hiragana"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined rounded text @click="closeDialog"> 閉じる </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: {
    value: {},
    event: {
      customer: {},
      salesStaff: {},
    },
  },
  components: {},
  data: () => ({
    flg: true,
    selectedCustomer: null,
    onTheDays: [
      { text: "該当なし", value: 0 },
      { text: "有", value: 1 },
      { text: "美容", value: 2 },
      { text: "先方", value: 3 },
    ],
    familyPhotos: [
      { text: "あり", value: 0 },
      { text: "なし", value: 1 },
      { text: "要確認", value: 2 },
    ],
    todoke: [
      { text: "未", value: 0 },
      { text: "済", value: 1 },
    ],
    years: [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032],
    customers: [],
    customerSearch: "",
    customerLoading: false,
    originalEvent: null,
    passwordDialog: false,
    historyDialog: false,
  }),
  computed: {
    ...mapState("users", {
      users: (state) => state.data.sort((a, b) => a.code - b.code),
    }),
    ...mapState("eventTypes", {
      eventTypes: (state) => state.data.sort((a, b) => a.value - b.value),
    }),
    active: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    wareki: function () {
      if (this.event.celemony && this.event.celemony.year) {
        const dateText = String(this.event.celemony.year) + "-01-01";
        const dateValue = new Date(dateText);
        const result = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
          era: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(dateValue);
        return result.substring(0, result.indexOf("年") + 1);
      } else {
        return "";
      }
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
  methods: {
    closeDialog() {
      this.$emit("result", { result: "cancel", event: this.event });
      this.active = false;
    },
  },
};
</script>
