<template>
  <v-row class="sf-day-header">
    <v-col class="col-4">
      <v-sheet v-if="holiday != ''">
        <span>{{ holiday }}</span>
      </v-sheet>
    </v-col>
    <v-col class="col-4">
      <v-btn fab small depressed :color="buttonColor" class="sf-day-button">{{ day }}</v-btn>
    </v-col>
    <v-col class="col-4">
      <span
        v-bind:class="{
          'red--text': rokuyo == '大安',
        }"
        >{{ rokuyo }}</span
      >
    </v-col>
  </v-row>
</template>
<style scoped>
@media print {
  .sf-day-button {
    background-color:white !important;
    color:black !important;
  }
}
</style>
<style>
.v-calendar-daily_head-day-label {
  width:300px !important;
  left:0px !important;
  text-align: center;
  position:relative !important;
  margin:auto;
}
</style>
<script>
import qreki from "../../plugins/qreki";

export default {
  props: {
    value: Date,
  },
  data: () => ({
      koyomi:qreki,
  }),
  computed: {
    year: function () {
      return this.value.getFullYear();
    },
    month: function () {
      return this.value.getMonth() + 1;
    },
    day: function () {
      return this.value.getDate();
    },
    weekDay: function() {
        return this.value.getDay();
    },
    holiday: function () {
      return this.koyomi[this.year][this.month][this.day].holiday;
    },
    rokuyo: function () {
      return this.koyomi[this.year][this.month][this.day].rokuyo;
    },
    buttonColor: function () {
        if(this.holiday != "") {
            return "#FFB6C1";
        }
        else if(this.weekDay == 6) {
            return "#87CEFA";
        }
        else if(this.weekDay == 0) {
            return "#FFB6C1";
        }
        else {
            return "lightgray";
        }
    },
  }
};
</script>
