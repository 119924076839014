<template>
    <v-sheet class="ma-4">
        <v-row>
            <v-btn :disabled="loading" color="primary" @click="setAllUndefinedStudioToTakabatake">スタジオ未設定の予定を高畑に一括設定する</v-btn>
        </v-row>
        <v-row>
            <v-textarea v-model="log" readonly />
        </v-row>
    </v-sheet>
</template>

<script>
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";

export default {
    data: () => {
        return {
            loading:false,
            log:"",
        }
    },
    methods: {
        setAllUndefinedStudioToTakabatake() {
            this.loading = true;
            const func = httpsCallable(functions, 'test');
            func()
            .then((result) => {
                const data = result.data;
                this.log += data + "\n";
                this.loading = false;
            });
        }
    }
}
</script>