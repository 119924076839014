<template>
  <v-dialog v-model="active" width="60%">
    <v-card>
      <v-card-title> {{ event.customer.name }}さんの履歴 </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :server-items-length="totalItems"
          :options.sync="options"
          dense
          class="elevation-1"
          @click:row="showDetail"
        >
          <template v-slot:top> </template>
          <template v-slot:no-data>
            <v-alert type="info">履歴はありません。</v-alert>
          </template>
          <template v-slot:bottom> </template>
        </v-data-table>
      </v-card-text>
      <event-history-detail-dialog v-if="detailDialog"
        v-model="detailDialog"
        :event="selectedHistory"
      ></event-history-detail-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import moment from "moment";
import EventHistoryDetailDialog from "./EventHistoryDetailDialog.vue";
import { db } from "../../plugins/firebase";

export default {
  components: { EventHistoryDetailDialog },
  props: {
    value: {},
    event: {
      eventId: "",
      customer: {
        name: "",
      },
      updatedBy: {
        name: "",
      },
    },
  },
  data: () => ({
    headers: [
      { text: "日時", value: "updatedText" },
      { text: "担当者", value: "updatedBy.name" },
    ],
    items: [],
    totalItems: 0,
    loading: true,
    options: {},
    detailDialog: false,
    selectedHistory: null,
  }),
  computed: {
    active: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  watch: {
    // DataTableのOption変更を検知してデータ取得を呼び出す
    options: {
      handler() {
        this.loadItems();
      },
      deep: true,
    },
  },
  methods: {
    // データの取得（DataTableのOptionに応じて）
    async loadItems() {
      this.loading = true;

      // Firestoreの初期化
      // const db = getDatabase();

      // クエリを初期化
      let q = query(collection(db, "history"));

      // イベントIDで抽出
      q = query(q, where("eventId", "==", this.event.__id));

      // Optionを展開
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // ソートを設定
      if (sortBy.length === 1 && sortDesc.length === 1) {
        q = query(q, orderBy("updated", sortDesc[0] ? "desc" : "asc"));
      }

      // ページングを設定
      if (1 < page) {
        q = query(q, startAt(itemsPerPage * (page - 1)));
      }
      q = query(q, limit(itemsPerPage));

      // ダウンロード
      const result = [];
      const snapshot = await getDocs(q);
      //console.log(snapshot.size);
      snapshot.forEach((doc) => {
        const docData = Object.assign({}, doc.data());
        docData.updatedText = moment(docData.updated.toDate()).format(
          "YYYY/MM/DD HH:mm"
        );
        result.push(docData);
      });
      this.items = result;
      this.totalItems = snapshot.size;

      this.loading = false;
    },
    showDetail(history) {
      this.selectedHistory = history;
      this.detailDialog = true;
    },
  },
};
</script>
