<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="timeString"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="timeString"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="timeString"
      :allowed-hours="allowedHours"
      :allowed-minutes="allowedStep"
      full-width
      format="24hr"
      @click:minute="$refs.menu.save(time)"
    ></v-time-picker>
  </v-menu>
</template>
<style scoped>
  .v-text-field >>> input {
    font-size:1.3em;
  }
</style>
<script>
import moment from 'moment';
export default {
  props: {
    value: Number,
    label: String
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    allowedHours: v => (8 <= v && v <= 19),
    allowedStep: m => m % 5 === 0,
  },
  computed: {
    date: {
      get(){
        return moment(this.value).startOf('day').toDate();
      }
    },
    time: {
      get() {
        return moment(this.value).toDate();
      },
      set(val) {
        const nm = moment(val);
        const uv = nm.valueOf();
        this.$emit("input", uv);
      },
    },
    timeString: {
      get() {
        return this.time.toTimeString().substr(0, 5);
      },
      set(val) {
        const date = this.date;
        const tm = moment(val, "hh:mm:ss");
        const tm2 = moment(date).add(tm.hour(), 'hours').add(tm.minute(), 'minutes');
        this.time = tm2.toDate();
      },
    },
  },
};
</script>