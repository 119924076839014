import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue"
import Reservation from "../views/Reservation.vue";
import User from "../views/User.vue";
import Box from "../views/Box.vue";
import Visitation from "../views/Visitation.vue";
import EventType from "../views/EventType.vue";
import Download from "../views/Download.vue";
import Other from "../views/Other.vue";
import Studio from "../views/Studio.vue";
import Place from "../views/Place.vue";
import PreparationPlace from "../views/PreparationPlace.vue";
import Store from '@/store/index.js'
import FreeTimeSearch from '../views/FreeTimeSearch.vue';
import Celemony from '../views/Celemony.vue';
import { doc, getDoc, collection } from "firebase/firestore";
import { onAuthStateChanged } from 'firebase/auth'
import { db, auth } from "../plugins/firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Reservation",
    component: Reservation,
    meta: { title: "予約", requiresAuth: true }
  },
  {
    path: "/reservation/:studio",
    name: "ReservationWithStudio",
    component: Reservation,
    meta: { title: "予約", requiresAuth: true }
  },
  {
    path: "/reservation/:studio/:pdate",
    name: "ReservationWithStudioAndDate",
    component: Reservation,
    meta: { title: "予約", requiresAuth: true }
  },
  {
    path: "/visitation/:place",
    name: "Visitation",
    component: Visitation,
    meta: { title: "来店予約", requiresAuth: true }
  },
  {
    path: "/box",
    name: "Box",
    component: Box,
    meta: { title: "日付未定", requiresAuth: true }
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: { title: "ユーザー", requiresAuth: true },
  },
  {
    path: "/event-type",
    name: "EventType",
    component: EventType,
    meta: { title: "購入区分", requiresAuth: true },
  },
  {
    path: "/studio",
    name: "Studio",
    component: Studio,
    meta: { title: "スタジオ", requiresAuth: true },
  },
  {
    path: "/freetime",
    name: "FreeTime",
    component: FreeTimeSearch,
    meta: { title: "空き時間検索", requiresAuth: true },
  },
  {
    path: "/place",
    name: "Place",
    component: Place,
    meta: { title: "来店場所", requiresAuth: true },
  },
  {
    path: "/preparationPlace",
    name: "PreparationPlace",
    component: PreparationPlace,
    meta: { title: "準備場所", requiresAuth: true },
  },
  {
    path: "/download",
    name: "Download",
    component: Download,
    meta: { title: "ダウンロード", requiresAuth: true },
  },
  {
    path: "/celemony",
    name: "Celemony",
    component: Celemony,
    meta: { title: "成人式当日", requiresAuth: true },
  },
  {
    path: "/other",
    name: "Other",
    component: Other,
    meta: { title: "その他", requiresAuth: true },
  },
  {
    path: "/about",
    name: "About",
    meta: { title: "アバウト", requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "Login" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Firebase Authを初期化
  //const auth = getAuth();


  // 認証状態が変更したときのハンドラーを設定
  onAuthStateChanged(auth, async (user) => {
    if (user != null) {

      if (Store.state.auth.currentUser.id !== user.uid) {
        // Firestoreからロール（一般 or 管理者）を取得する
        // const db = getDatabase();
        const userRoleRef = await doc(collection(db, 'userRoles'), user.uid);
        if (userRoleRef) {
          const userRoleSnap = await getDoc(userRoleRef);
          const userRoleData = userRoleSnap.data();

          // ロールを含め、ユーザーをセットする
          Store.commit('auth/setUser', {
            id: user.uid,
            email: user.email,
            role: userRoleData.role
          });
        }
      }

      // ステータスを認証済にする。
      Store.commit('auth/setAuthenticated', true);
    }

    // ルートを更新
    if (to.matched.some(record => record.meta.requiresAuth) && !Store.state.auth.authenticated) {
      next({ path: '/login', query: { redirect: to.fullPath } });
    }
  });

  next();
});

export default router;
