import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import ja from 'vuetify/lib/locale/ja'

export default new Vuetify({
    lang: {
        locales: { ja },
        current: 'ja'
    }
});
