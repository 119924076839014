
<template>
  <v-app>
    <v-app-bar app color="primary" dark clipped-left class="sf-appbar">
      <v-app-bar-nav-icon class="d-print-none">
        <v-btn
          fab
          dark
          small
          depressed
          color="primary"
          @click="drawer = !drawer"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>

      <v-app-bar-title class="d-print-none" >
        <span style="min-width: 10rem;">{{ $route.matched.slice(-1)[0].meta.title }}</span>
      </v-app-bar-title>

      
      <calendar-bar v-if="$route.path == '/' || $route.path.startsWith('/reservation')" class="ml-6"></calendar-bar>
      <visitation-bar v-if="$route.path.startsWith('/visitation')" class="ml-6"></visitation-bar>
    </v-app-bar>

    <v-navigation-drawer app clipped v-model="drawer" class="d-print-none">
      <navigation-menu></navigation-menu>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-footer app color="secondary" class="d-print-none" dark>STICKY FINGERS.LLC {{test}}</v-footer>
  </v-app>
</template>

<style scoped>
  @media print {
    .v-application .primary.sf-appbar {
      background-color:white !important;
      color:black;
    }
  }
</style>
<script>
import { mapActions } from 'vuex';
import CalendarBar from "../components/CalendarBar.vue";
import VisitationBar from "../components/VisitationBar.vue";
/* eslint vue/no-unused-components: 0 */
import NavigationMenu from "../components/NavigationMenu.vue";
export default {
  components: { NavigationMenu, CalendarBar, VisitationBar },
  name: "App",

  data: () => ({
    drawer: null,
    test: ""
  }),
  created: async function() {
    await this.loadUser({refresh:false});
    await this.loadEventTypes({refresh:false});
    await this.loadStudio({refresh:false});
    await this.loadPlace({refresh:false});
    await this.loadPreparationPlace({refresh:false});
    this.test = "tt" + process.env.FIRESTORE_EMULATOR_HOST;
  },
  methods: {
    ...mapActions('users', {loadUser:'load'}),
    ...mapActions('eventTypes', {loadEventTypes:'load'}),
    ...mapActions('studio', {loadStudio:'load'}),
    ...mapActions('place', {loadPlace:'load'}),
    ...mapActions('preparationPlace', {loadPreparationPlace:'load'}),
  }
};
</script>
